import React, { useEffect } from "react";

import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Error from "@material-ui/icons/Error";
import {getAllAlerts} from "../../api/dpmoApiServices/AlertsServices";
import Typography from "@material-ui/core/Typography";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import { ListItem } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
      display: "inline-block"
    },
    paper: {
      marginRight: theme.spacing(2)
    }
}));

const AlertButton = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [openedAlert, setOpenedAlert] = React.useState(null);
    const [alerts, setAlerts] = React.useState([]);
    const [nbAlerts, setNbAlerts] = React.useState(0);
    const anchorRef = React.useRef(null);
  
  
    useEffect(() => {
        async function fetchData(){
            const res = await getAllAlerts()
            var ret = {};
            res.map(alert=>{
                if(ret[alert.subject_type + "_" + alert.subject_id] == undefined){
                    ret[alert.subject_type + "_" + alert.subject_id] = []
                }
                ret[alert.subject_type + "_" + alert.subject_id].push(alert)
            })
            setAlerts(ret)
            setNbAlerts(res.length)
            
        }
        fetchData()

        
  
    },[])
  
    function handleToggle() {
      setOpen(prevOpen => !prevOpen);
    }
  
    function handleClose(event) {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
      setOpenedAlert(null)
      setOpen(false);
    }

    return (
        <div className={classes.root}>
            <IconButton ref={anchorRef} onClick={handleToggle} color="inherit" style={{ outline: "none" }}>
                <Badge badgeContent={nbAlerts} color="secondary">
                <NotificationsIcon />
                </Badge>
            </IconButton>

            <Menu
                open={open}
                anchorEl={anchorRef.current}
                transition
                disablePortal
                placement="top-start"
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                PaperProps={{
                    style: {
                        padding: 0,
                        right: "5px",
                        transform: "translateX(-65px) translateY(+45px)"
                    }
                }}
                >
                <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                    {Object.keys(alerts).map(keyGroupAlert=>(

                        <MenuItem onClick={()=>setOpenedAlert(keyGroupAlert)}>
                            <ListItemIcon>
                                <Error />
                            </ListItemIcon>
                            <Typography variant="inherit">Alert in {keyGroupAlert.substr(0, keyGroupAlert.indexOf("_"))} n°{keyGroupAlert.substr(keyGroupAlert.indexOf("_")+1)}</Typography>
                            <Collapse in={openedAlert==keyGroupAlert} timeout="auto" unmountOnExit>
                                {alerts[keyGroupAlert].map(alert =>(
                                    <div>
                                        <Divider />
                                        <ListItem>
                                            <Typography variant="inherit">{alert.description}</Typography>
                                        </ListItem>
                                    </div>
                                ))}
                            </Collapse>
                        </MenuItem>
                    ))}

                </MenuList>
                </ClickAwayListener>
            </Menu>
        </div>
    );
}

export default AlertButton;
