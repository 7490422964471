import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Scrollbars } from "react-custom-scrollbars";
import Close from "@material-ui/icons/Close";
import Publish from "@material-ui/icons/Publish";
import IconButton from "@material-ui/core/IconButton";
import SaveBar from "./customInput/saveBar";
import Dialog from "@material-ui/core/Dialog";
import { Prompt } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    minHeight: '20vh',
    maxHeight: '100vh',
  },
  root: {
    display: "flex",
    minWidth: "960px",
    flexDirection: "column"
  },
  margin: {
    margin: theme.spacing(4),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(0),
    borderTopStyle: "solid",
    borderWidth: "1px"
  },
  header: {
    display: "flex",
    flexDirection: "row",
    margin: theme.spacing(4),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    justifyContent: "space-between",
    alignItems: "center"
  },
  upload: {
    display: "flex",
    flexDirection: "row",
    alignItems: "right"
  },
  title: {
    fontSize: "large"
  }
}));

const FormTemplate = ({ name, children, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog onClose="" aria-labelledby="simple-dialog-title" open maxWidth="md" onEscapeKeyDown={onClose} classes={{ paper: classes.dialogPaper }} >
      <div className={classes.root}>
        <div className={classes.header}>
          <div className={classes.title}>{name}</div>
          <div className={classes.upload}>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </div>
        </div>
        {/* <Scrollbars autoHeight> */}
        <div className={classes.margin}>{children}</div>
        {/* </Scrollbars> */}
      </div>
      {/*<Prompt when={true} message={"Quitter?"} />*/}
    </Dialog>
  );
};
export default FormTemplate;
