import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import jwt from "jsonwebtoken";
import { signIn } from "../api/dpmoApiServices/AuthServices";
import setAuthorizationToken from "../config/authorizationHeader";
import { ACCESS_TOKEN } from "../constant/token";
import { setCurrentUser, setUserAuthorisation } from "../ducks/auth/action-creators";
// import image from "../img/landing.png";
import image from "../img/fondLanding.jpg";
import dpmo from "../img/logodp.png";
import { getAllAuthorisationCurrentUser } from "../api/dpmoApiServices/GovernanceServices"
import { getUserByUsernameOrEmail, updateUserPassword } from "../api/dpmoApiServices/UserServices"
import store from "../ducks/store";
import FormTemplate from "../components/form/formTemplate";
import PasswordChangeForm from "../components/form/passwordChangeForm"
import Divider from '@material-ui/core/Divider';

import Tour from "./tour.js";


const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundImage: `url(${image})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      width: '100vw',
      height: '100vh'
    }
  },
  gradiant: {
    overflow: "hidden",
  },
  paper: {
    marginTop: theme.spacing(10),
    // marginBottom: theme.spacing(50),
    marginLeft: "auto",
    marginRight: theme.spacing(11),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(7),
    width: "min-content",
    borderRadius: 80,
    opacity: "90%",
  },
  paper2: {
    display: "flex",
    alignItems: "center",


  },
  paper3: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: theme.spacing(7),
    marginLeft: theme.spacing(7),
    padding: theme.spacing(3)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#26E1BC"
  },
  form: {
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#009179",

  },
  bigAvatar: {
    width: 45,
    height: 40
  },
  image: {
    width: '50vw',
    height: 'auto'
  },

}));

const initialValue = {
  usernameOrEmail: "",
  password: ""
};

function SignIn(props) {
  const classes = useStyles();

  const [connexion, setConnexion] = useState(initialValue);
  const [firstConnexion, setFirstConnexion] = useState(false);
  const [user, setUser] = useState({});
  const [errorMessage, setErrorMessage] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { history, setCurrentUser } = props;

    var response = ''

    if (connexion.usernameOrEmail !== "" || connexion.password !== "") {
      response = await signIn(connexion)



      const accessToken = response.accessToken;
      localStorage.setItem(ACCESS_TOKEN, accessToken);
      setAuthorizationToken(accessToken);
      setCurrentUser(jwt.decode(accessToken));
      //if (localStorage.getItem("langue") == null) {
      //  localStorage.setItem("langue", "GB")
      //}
      if (response.status == 0) {
        getUserByUsernameOrEmail(connexion.usernameOrEmail).then(resp => {
          setUser(resp)
          setFirstConnexion(true)
        })
      } else {
        const resp = await getAllAuthorisationCurrentUser()
        store.dispatch(setUserAuthorisation(resp))
        history.push("/tasks");
        console.log("salt")
      }
    }

    if (response == '') {
      setErrorMessage(true);
      return
    }

  };

  const handleChange = event => {
    const { name, value } = event.target;
    setConnexion({ ...connexion, [name]: value });
  };

  const handleNewPassword = data => {
    updateUserPassword(data)
    setFirstConnexion(false)
    setConnexion({ ...connexion, password: "" })
    localStorage.setItem(ACCESS_TOKEN, null);
  }




  return (
    <>
      <Paper className={classes.paper} id="guide_0">
        {/*<Tour></Tour>*/}
        <Avatar
          alt="DPMO"
          src={dpmo}
          variant="rounded"
          className={classes.bigAvatar}
        />

        <div className={classes.paper3}>
          {/* <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Sign in
                </Typography> */}
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Email Address"
              name="usernameOrEmail"
              autoComplete="email"
              onChange={handleChange}
              value={connexion.usernameOrEmail}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              autoComplete="current-password"
              onChange={handleChange}
              value={connexion.password}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <div>
              {errorMessage &&
                <span style={{ color: "red" }}>
                  Wrong password or email
                </span>
              }
            </div>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              //color="primary"
              onClick={handleSubmit}
              className={classes.submit}
              id="guide_1"
            >
              Sign In
            </Button>
          </form>
          <Divider />
          <Button>
            Forgot your password ?
          </Button>
        </div>


      </Paper>

      {firstConnexion &&
        <FormTemplate name="Set a new password for your first connection">
          <PasswordChangeForm user={user} create={handleNewPassword} />
        </FormTemplate>
      }

    </>
  );
}

const mapDispatchToProps = dispatch => {
  //setCurrentUser,
  return {
    setCurrentUser: user => dispatch(setCurrentUser(user))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(SignIn));
