import axios from 'axios';
import { dpmoApiUrl } from '../../constant/apiConfig'

export async function getAllNotifsTasks() {
    const res = await axios.get(dpmoApiUrl + "TaskNotificationService/getAllTaskNotif")
    return res.data
}

export async function getTasksNotified() {
    const res = await axios.get(dpmoApiUrl + "TaskNotificationService/getTasksNotified")
    return res.data
}


export async function addNewTaskNotif(idUser, idTask) {
    const res = await axios.post(dpmoApiUrl + "TaskNotificationService/addTaskNotified/" + idUser + "/" + idTask)
    return res.data
}

export async function removeTaskNotif(idTask) {
    const res = await axios.post(dpmoApiUrl + "TaskNotificationService/removeTaskNotified/" + idTask)
    return res.data
}



