import "./App.css";
import "./index.css"
import { Provider } from "react-redux";
import store from "./ducks/store.js";

import React, { Component, Suspense } from "react";
import { BrowserRouter as Router, HashRouter } from "react-router-dom";
import Routes from "./routes";
import { IntlProviderWrapper } from "./config/IntlProviderWrapper";

import { ACCESS_TOKEN } from "./constant/token";
import { AUTHORISATION } from "./constant/authorisation";
import setAuthorizationToken from "./config/authorizationHeader";
import { setCurrentUser, setUserAuthorisation } from "./ducks/auth/action-creators";
import jwt from "jsonwebtoken";
import { getAllAuthorisationCurrentUser } from "./api/dpmoApiServices/GovernanceServices"


class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      test: false
    }
  }

  componentWillMount() {
    if (localStorage[ACCESS_TOKEN]) {
      const data = jwt.decode(localStorage[ACCESS_TOKEN])
      if (data.exp > Math.round((new Date).getTime() / 1000)) {
        setAuthorizationToken(localStorage[ACCESS_TOKEN]);
        store.dispatch(setCurrentUser(data));
        getAllAuthorisationCurrentUser().then(resp => {
          store.dispatch(setUserAuthorisation(resp))
          this.setState({ test: true })
        })
      } else {
        localStorage.removeItem(ACCESS_TOKEN)
        this.setState({ test: true })
      }
    } else {
      this.setState({ test: true })
    }
    // document.body.style.zoom = 0.8
  }

  render() {
    return (
      this.state.test &&
      <Provider store={store}>
        <IntlProviderWrapper>
          <Suspense fallback={<span></span>}>
            <HashRouter>
              <Routes />
            </HashRouter>
          </Suspense>
        </IntlProviderWrapper>
      </Provider>
    );
  }
}
export default App;
