import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { useForm } from "../../hook/useFormState.js";

import SaveBar from "./customInput/saveBar";

const useStyles = makeStyles(theme => ({
  error: {
    color: "red"
  }
}));


const PasswordChangeForm = ({
  create,
  user,
  testOldPassword = false,
}) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    oldPassword: "",
    password: "",
    password2: ""
  });
  const [errors, setErrors] = useState({
    password: false,
    oldPassword: false
  })

  const checkErrors = () => {
    var ret = false
    if (values.password !== values.password2) {
      ret = true
    }
    setErrors({ password: ret })
  }

  const handleChange = event => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
    checkErrors()
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (!errors.password) {
      const resp = create(values)


      if (testOldPassword) {
        if (!resp) {
          setErrors({ ...errors, oldPassword: true })
        }
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        style={{ textAlign: "center", marginTop: "10px" }}
        container
        spacing={2}
      >
        {testOldPassword &&
          <>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Current Password"
                type="password"
                name="oldPassword"
                value={values.oldPassword}
                onChange={handleChange}
              />
              {errors.oldPassword &&
                <div>
                  <span className={classes.error}>Incorect Password</span>
                </div>
              }
            </Grid>
          </>
        }
        <Grid onBlur={checkErrors} item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            label="New Password"
            type="password"
            name="password"
            value={values.password}
            onChange={handleChange}
          />
        </Grid>
        <Grid onBlur={checkErrors} item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            error={errors.password}
            label="Confirm Your New Password"
            type="password"
            name="password2"
            value={values.password2}
            onChange={handleChange}
          />
          {errors.password &&
            <div>
              <span className={classes.error}>Passwords should be the same</span>
            </div>
          }
        </Grid>
      </Grid>
      <SaveBar
        onSubmit={handleSubmit}
      />
    </form>
  );
};

export default PasswordChangeForm;
