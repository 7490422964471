import React, {useState, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ArrowRight";
import ExpandMore from "@material-ui/icons/ArrowDropDown";
import { Link } from "react-router-dom";
import {getAllWorkpackageFromProject} from "../../api/dpmoApiServices/workpackageService";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 360
  },
  title: {
    padding: 0,
    paddingLeft: theme.spacing(4)
  },
  nested: {
    padding: 0,
    paddingLeft: theme.spacing(5)
  },
  link: {
    textDecoration: "none"
  },
  white: {
    color: "#fff"
  }
}));

export default function NestedList(props) {
  const { title, projectId, pfId } = props;
  const classes = useStyles();
  const [open, setopen] = React.useState(false);
  const [dataWP, setDataWP] = useState([]);

  function handleClick() {
    setopen(!open);
  }

  useEffect(() => {
    async function fetchCriticalityList() {
      const response = await getAllWorkpackageFromProject(projectId);
      setDataWP(response);
    }
    fetchCriticalityList();
  },[]);

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      <ListItem button onClick={handleClick} className={classes.title}>
     <div>
        {open ? (
          <ExpandMore className={classes.white} />
        ) : (
          <ExpandLess className={classes.white} />
        )}
        </div>
        <ListItemText primary={title} className={classes.white} />
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {dataWP.map(item => (
            <ListItem
              key={item.id}
              button
              className={classes.nested}
              component={Link}
              to={"/portfolio/" + pfId + "/WPDashboard/" + item.id}
            >
              <ListItemText primary={item.description} className={classes.white} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </List>
  );
}
