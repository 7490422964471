import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import './slideShow.css'
import guideTask from "../../img/Tasks.png";
import guideChat from "../../img/Chats.png";
import guideRooms from "../../img/Room.png";
import guideCreateRooms from "../../img/createRooms.png";
import guideFiles from "../../img/Files.png";
import guideProfil from "../../img/Profil.png";
import guideGantt from "../../img/Gantt.png";
import guideReport from "../../img/Report.png";
import guideArchive from "../../img/Archive.png";
import guideDash from "../../img/Dashboard.png";
import guideContact from "../../img/Contact.png";
import guideTaskFile from "../../img/TaskFile.png";
import guideEdit from "../../img/Edit_task.png";
import guideManage from "../../img/manage.png";
import guideCheck from "../../img/checklist.png";
import HeaderNavigationName from "../../components/headerNavigationName";


const SlideShow = ({ setTitle }) => {
  const a = (z, e) => {

    if (e == 0) {
      setTitle("1/15 - My Profil")
    }
    else if (e == 1) {
      setTitle("2/15 - Tasks Dashboard")
    }
    else if (e == 2) {
      setTitle("3/15 - Tasks Report")
    }
    else if (e == 3) {
      setTitle("4/15 - Tasks")
    }
    else if (e == 4) {
      setTitle("5/15 - Archive tasks")
    }
    else if (e == 5) {
      setTitle("6/15 - Create/Edit tasks")
    }
    else if (e == 6) {
      setTitle("7/15 - Manage my team")
    }
    else if (e == 7) {
      setTitle("8/15 - Checklist")
    }
    else if (e == 8) {
      setTitle("9/15 - Tasks Gantt View")
    }
    else if (e == 9) {
      setTitle("10/15 - Tasks Files")
    }
    else if (e == 10) {
      setTitle("11/15 - My Chat")
    }
    else if (e == 11) {
      setTitle("12/15 - Create Rooms")
    }
    else if (e == 12) {
      setTitle("13/15 - My Rooms")
    }
    else if (e == 13) {
      setTitle("14/15 - My Files")
    }
    else if (e == 14) {
      setTitle("15/15 - Contact Support")
    }
  }



  return (
    <div>
      <Slide easing="ease" onChange={a} autoplay={false}>
        <div className="each-slide">
          <div style={{ 'backgroundImage': `url(${guideProfil})`}}>
          </div>
        </div>
        <div className="each-slide">
          <div style={{ 'backgroundImage': `url(${guideDash})`, width: "100%"}}>
          </div>
        </div>
        <div className="each-slide">
          <div style={{ 'backgroundImage': `url(${guideReport})`, width: "100%" }}>
          </div>
        </div>
        <div className="each-slide">
          <div style={{ 'backgroundImage': `url(${guideTask})`, width: "100%" }}>
          </div>
        </div>
        <div className="each-slide">
          <div style={{ 'backgroundImage': `url(${guideArchive})`, width: "100%" }}>
          </div>
        </div>
        <div className="each-slide">
          <div style={{ 'backgroundImage': `url(${guideEdit})`, width: "100%" }}>
          </div>
        </div>
        <div className="each-slide">
          <div style={{ 'backgroundImage': `url(${guideManage})`, width: "100%" }}>
          </div>
        </div>
        <div className="each-slide">
          <div style={{ 'backgroundImage': `url(${guideCheck})`, width: "100%" }}>
          </div>
        </div>
        <div className="each-slide">
          <div style={{ 'backgroundImage': `url(${guideGantt})`, width: "100%" }}>
          </div>
        </div>
        <div className="each-slide">
          <div style={{ 'backgroundImage': `url(${guideTaskFile})`, width: "100%" }}>
          </div>
        </div>
        <div className="each-slide">
          <div style={{ 'backgroundImage': `url(${guideChat})` }}>
          </div>
        </div>
        <div className="each-slide">
          <div style={{ 'backgroundImage': `url(${guideCreateRooms})` }}>
          </div>
        </div>
        <div className="each-slide">
          <div style={{ 'backgroundImage': `url(${guideRooms})` }}>
          </div>
        </div>
        <div className="each-slide">
          <div style={{ 'backgroundImage': `url(${guideFiles})` }}>
          </div>
        </div>
        <div className="each-slide">
          <div style={{ 'backgroundImage': `url(${guideContact})` }}>
          </div>
        </div>
      </Slide>
    </div >
  )
};

export default SlideShow;