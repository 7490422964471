import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  test: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    marginTop: "10px",
    fontSize: "23px",
    color: "#15c9b9",
    fontFamily: "Ubuntu",
    paddingTop: "1vh"
  }
}));

const HeaderNavigationName = (props) => {
  const { title, id, displayApproval = false, idProject = 0 } = props
  const [state, setState] = useState(displayApproval)
  const classes = useStyles();

  useEffect(() => {
    setState(displayApproval);
  }, [displayApproval]);

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center"
      }}>
      <div className={classes.test}>
        <FormattedMessage id={id} defaultMessage={title} />
      </div>
    </div>
  );
};

export default HeaderNavigationName;

HeaderNavigationName.propTypes = {
  title: PropTypes.string.isRequired
};
