import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { drawerWidth } from "../constant/webappConstants.js";
import { Scrollbars } from "react-custom-scrollbars";
import Container from "@material-ui/core/Container";
import { connect } from "react-redux";

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    backgroundColor: "#F4F4F4",
    [theme.breakpoints.down("lg")]: {
      marginLeft: -drawerWidth
    }
  },
  webapp: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  toolbar: {
    minHeight: "64px"
  },
  contentShift: {
    [theme.breakpoints.up("md")]: {
      marginLeft: 0
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  }
}));

const Content = ({ children, open }) => {
  const classes = useStyles();
  return (
    <main
      className={clsx(classes.content, {
        [classes.contentShift]: open
      })}
    >
      <Scrollbars>
        <Container maxWidth="xl" className={classes.webapp}>
          <div className={classes.toolbar}/>
          {children}
        </Container>
      </Scrollbars>
    </main>
  );
};

Content.propTypes = {
  open: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  return {
    open: state.navigation.sidebar.isOpen
  };
};

export default connect(mapStateToProps)(Content);
