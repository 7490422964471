import { SET_PORTFOLIO, TOOGLE_SIDEBAR } from "./action-types.js";

export const togglePortfolio = portfolio => ({
  type: SET_PORTFOLIO,
  portfolio
});

export const toggleSidebar = () => ({
  type: TOOGLE_SIDEBAR,
  test:true
});
