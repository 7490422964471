import axios from 'axios';
import { dpmoApiUrl } from '../../constant/apiConfig'

export async function signIn(user) {
    const res = await axios.post(dpmoApiUrl + "api/auth/signin", user
    )
    return await res.data
}
export async function signup(data) {
    const res = await axios.post(dpmoApiUrl + "api/auth/signup", data)
    return await res.data
}

export async function signupInvited(data, session) {
    const res = await axios.post(dpmoApiUrl + "api/auth/signup/" + session, data)
    return await res.data
}



export async function signupWithPw(data) {
    const res = await axios.post(dpmoApiUrl + "api/auth/signupWithPW", data)
    return await res.data
}

export async function signupWithPwInvited(data, session) {
    const res = await axios.post(dpmoApiUrl + "api/auth/signupWithPWInvited/" + session, data)
    return await res.data
}
export async function signOut() {
    const res = await axios.post(dpmoApiUrl + "api/auth/signout")
    return await res.data
}

export async function addCompanyTokenInvitation(token) {
    const res = await axios.post(dpmoApiUrl + "api/auth/addToken", token)
    return await res.data
}

