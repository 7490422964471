import React from "react";

import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { IntlContext } from "../../config/IntlProviderWrapper";
import LanguageFlag from "./LanguageFlag"
import ReactCountryFlag from "react-country-flag";
import { withStyles } from "@material-ui/core/styles";


const Language = [
  { value: "GB", name: "English" },
  { value: "Es", name: "Español" },
  { value: "De", name: "Deutsch" },
  { value: "Fr", name: "Français" }
];

const styles = theme => ({
  flag: {
    width: "1vw",
    marginLeft: "3vw"
  },
  position: {
    marginLeft: "35%",
    marginRight: "65%"
  }
});



class LanguageButton extends React.Component {
  constructor(props) {
    super(props);
    var langue = localStorage.getItem("langue")
    if (langue === null) {
      langue = "GB"
    }
    this.state = {
      language: langue
    };
  }


  handleChange = (e, toogleLanguage) => {
    const { value } = e.target;
    localStorage.setItem("langue", value)
    toogleLanguage(value);
  };

  handleChangeDash = () => {

  };

  render() {
    const { classes } = this.props;
    return (
      <IntlContext.Consumer>
        {({ toogleLanguage, locale }) => (
          <div style={{ marginTop: "9px" }}>
            <TextField
              onChange={e => this.handleChange(e, toogleLanguage)}
              value={this.state.language}
              disabled={false}
              select
              className={classes.position}
            >
              {Language.map(option => (
                <MenuItem
                  onClick={() => this.handleChangeDash()}
                  key={option.value}
                  value={option.value}>
                  {option.name}

                  <LanguageFlag flag={option.value} />

                </MenuItem>
              ))}
            </TextField>
          </div>
        )}
      </IntlContext.Consumer>
    );
  }
}


export default withStyles(styles)(LanguageButton);
