import axios from 'axios';
import { dpmoApiUrl } from '../../constant/apiConfig'

export async function getMessageByDiscussionId(id) {
    const res = await axios.get(dpmoApiUrl + "api/discussion_messages/idDiscussion/" + id)
    return res.data
}

export async function getUsersByDiscussionId(id) {
    const res = await axios.get(dpmoApiUrl + "api/discussion_junction/idDiscussion/" + id + "/user")
    return res.data
}

export async function getAllUsersNotInDiscussion(id) {
    const res = await axios.get(dpmoApiUrl + "api/discussion_junction/idDiscussion/" + id + "/notUser")
    return res.data
}

export async function getAllUsersNotInDiscussionBySession(idDiscussion, idSession) {
    const res = await axios.get(dpmoApiUrl + "api/discussion_junction/idDiscussion/" + idDiscussion + "/" + idSession + "/notUser")
    return res.data
}

export async function addUserToDiscussion(data) { //idDiscussion, user
    const res = await axios.post(dpmoApiUrl + "api/discussion_junction/", data)
    return res.data
}


export async function postMessage(data) {     //idTender, sender{idsender}, commentDate, commentContent
    const res = await axios.post(dpmoApiUrl + "api/discussion_messages/", data)
    return res.data
}

export async function getDiscussionsByInitiator() {
    const res = await axios.get(dpmoApiUrl + "api/discussion/initiator")
    return res.data
}

export async function getDiscussionByIdAndType(id, type) {
    const res = await axios.get(dpmoApiUrl + "api/discussion/idAndType/" + id + "/" + type)
    return res.data
}
export async function getDiscussionById(id) {
    const res = await axios.get(dpmoApiUrl + "api/discussion/idDiscussion/" + id)
    return res.data
}

export async function postDiscussion(data) {
    const res = await axios.post(dpmoApiUrl + "api/discussion/", data)
    return res.data
}

export async function updateDiscussion(data) {
    const res = await axios.put(dpmoApiUrl + "api/discussion/update", data)
    return res.data
}

export async function getAllDiscussions() {
    const res = await axios.get(dpmoApiUrl + "api/discussion_junction/getAll")
    return res.data
}

export async function getMessagesPending(idDiscussion) {
    const res = await axios.get(dpmoApiUrl + "api/discussionJunction/" + idDiscussion + "/messagesPending")
    return res.data
}

export async function addMessagesPending(idDiscussion) {
    const res = await axios.get(dpmoApiUrl + "api/discussionJunction/" + idDiscussion + "/addMessagesPending")
    return res.data
}

export async function resetMessagesPending(idDiscussion) {
    const res = await axios.get(dpmoApiUrl + "api/discussionJunction/" + idDiscussion + "/resetMessagesPending")
    return res.data
}

export async function getAllDiscussionPending() {
    const res = await axios.get(dpmoApiUrl + "api/discussionJunction/getAllMessagePending")
    return res.data
}

export async function getAllDiscussionsChunk(number) {
    const res = await axios.get(dpmoApiUrl + "api/discussion_junction/getAll/" + number)
    return res.data
}

// export async function getDiscussionByTaskId(id) {
//     const res = await axios.get(dpmoApiUrl + "api/discussion/idTask/" + id)
//     return res.data
// }

// export async function getDiscussionByWPId(id) {
//     const res = await axios.get(dpmoApiUrl + "api/discussion/idWP/" + id)
//     return res.data
// }