import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { FormattedMessage } from "react-intl";
import Lock from '@material-ui/icons/Lock';
import { Icon } from "@material-ui/core";
import saveImg from "../../../img/Save.jpeg";
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
  button: {
    //margin: "5px",
    textTransform: "none",
    fontSize: "medium",
    border:"none"
  },
  bar: {
    display: "flex",
    //marginTop: "20px",
    //marginBottom: "10px",
    justifyContent: "flex-end"
  }
}));

const SaveBar = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.bar}>
      <Tooltip title="Save">
      <Button
        type="submit"
        className={classes.button}
        variant="outlined"
        color="primary"
        onClick={props.onSaveDraft}
        disabled={props.disabled}
      >
        <img src={saveImg} height="20px" width="20px" />
        <FormattedMessage id="operationButton.save" defaultMessage=" " />
      </Button>
    </Tooltip>
    </div>
  );
};

export default SaveBar;

SaveBar.propTypes = {
  onSaveDraft: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};
