import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import Hidden from "@material-ui/core/Hidden";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import ChatIcon from "@material-ui/icons/Chat";
import Paper from "@material-ui/core/Paper"
import Badge from "@material-ui/core/Badge";
import CalendarIcon from "@material-ui/icons/DateRange"
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import ProfilMenu from "./profilMenu.js";
import LanguageButton from './LanguageButton'
import LanguageFlag from "./LanguageFlag"
import AlertButton from "./alertButton.js";
import { toggleSidebar } from "../../ducks/navigation/action-creators";
import { connect } from "react-redux";
import { getMyMembership, getCompany, getSessionUsers, getUserSession, encodeSession } from "../../api/dpmoApiServices/CompanyServices"
import { getTutoChecked, isTuto, updateTuto, getCurrentUser, deleteUser } from "../../api/dpmoApiServices/UserServices"
import Avatar from "@material-ui/core/Avatar";
import dpmo from "../../img/logodp.png";
import trial from "../../img/freetrial.png";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Aerospace from "../../img/Aerospace.png"
import Alstom from "../../img/Alstom.png"
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Tooltip from '@material-ui/core/Tooltip';
import Close from "@material-ui/icons/Close";
import SlideShow from "../slideshow/slideShow.js"
import GroupRoundedIcon from '@material-ui/icons/GroupRounded';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { sendInvitationEmail, sendSupportMail } from "../../api/dpmoApiServices/MailServices"
import { signupInvited } from "../../api/dpmoApiServices/AuthServices";
import DeleteIcon from '@material-ui/icons/Delete';
import { deleteCurrentUser } from "../../ducks/auth/action-creators.js";
import TeamSelectField from '../form/customInput/teamSelectField';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: 1300,
    backgroundColor: "#F4F4F4",
    color: "#111",
    marginBottom: 20
  },
  grow: {
    //flexGrow: 1,
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  menuButton: {},
  divider: {
    borderRight: "0.1em solid #777",
    paddingTop: "3em"
  },
  toolbar: {
    color: "#818181",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  avatar: {
    margin: 10,
    color: "#fff"
  },
  button: {
    color: "#009178"
  },
  paperFiles: {
    outline: 'none',
    backgroundColor: theme.palette.background.paper,
    borderRadius: "30px",
    paddingLeft: "2vh",
    paddingRight: "2vh",
    paddingBottom: "2vh",
    width: "1350px",
    border: "3px solid #15c9b9",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  },
  paperSupport: {
    outline: 'none',
    backgroundColor: theme.palette.background.paper,
    borderRadius: "30px",
    paddingLeft: "2vh",
    paddingRight: "2vh",
    paddingBottom: "2vh",
    width: "40%",
    border: "3px solid #15c9b9",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  },
  paperSession: {
    outline: 'none',
    backgroundColor: theme.palette.background.paper,
    borderRadius: "30px",
    paddingLeft: "2vh",
    paddingRight: "2vh",
    paddingBottom: "2vh",
    width: "40%",
    border: "3px solid #15c9b9",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",

  },
  right: {
    display: "flex",
    alignItems: "center"
  },
  tets: {
    alignItems: "center"
  },
  search: {
    position: "relative",
    alignItems: "center",
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  searchIcon: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200
    }
  },
  bigAvatar: {
    width: 130,
    height: 40,
    position: "absolute",
    left: "50%",
    right: "50%",
    marginLeft: "55px"
  },
  logo: {
    width: 40,
    height: 36,
    position: "absolute",
    left: "75px",

  },
  submit: {
    backgroundColor: "#009178",
    textTransform: "capitalize",
    color: "white",
  },
  logoCompany: {
    position: "absolute",
    left: "50%",
    right: "50%",
    //minWidth: 210,
    height: 35,

  },
  flag: {
    height: '2em'
  }
}));

const initialValue = {
  email: "",
  name: "",
  familyName: "",
  role: "Task Manager",

};


function HeaderBar(props) {
  const { toggleSidebar } = props;
  const classes = useStyles();
  const [logo, setLogo] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [member, setMember] = React.useState({
    role: {
      name: ""
    },
    userSummary: {
      name: ""
    },
    company: {
      company_name: ""
    }
  });
  const [flag, setFlag] = React.useState("GB")
  const [openHelp, setOpenHelp] = useState(false)
  const [checked, setChecked] = useState()
  const [openSessionTool, setOpenSessionTool] = useState(false)
  const [listEmplDisplay, setListEmplDisplay] = useState([])
  const [session, setSession] = useState("")
  const [email, setEmail] = useState("")
  const [subscribe, setSubscribe] = useState(initialValue);
  const [placesLeft, setPlacesLeft] = useState(5)
  const [disableBtn, setDisableBtn] = useState(false)
  const [isSessionAdmin, setIsSessionAdmin] = useState(false)
  const [currentUser, setCurrentUser] = useState()
  const [openSupportTool, setOpenSupportTool] = useState(false)
  const [topic, setTopic] = useState("")
  const [textToSupport, setTextToSupport] = useState("")
  const [title, setTitle] = useState("My Profil")
  const [errorMessage, setErrorMessage] = useState(false)

  const handleChange = event => {
    const { name, value } = event.target;
    setSubscribe({ ...subscribe, [name]: value });
  };

  const setUserList = async () => {
    const session = await getUserSession()
    const userList = await getSessionUsers(session.id)
    setSession(session)
    setListEmplDisplay(userList)
  }

  useEffect(() => {

    const func = async () => {
      const current = await getCurrentUser()
      setCurrentUser(current)
      const membership = await getMyMembership()
      if (membership[0].role.name == "Company Admin") { setIsSessionAdmin(true) }
      const company = await getCompany(membership[0].company.id)
      setLogo(company.logo)
      membership[0].company = company
      setMember(membership[0])
    }

    func()

    var langue = localStorage.getItem("langue")
    if (langue === null) {
      langue = "GB"
    }
    setFlag(langue)
    setUserList()

    getTutoChecked().then(resp => {
      if (resp == true) {
        setOpenHelp(true)
      }
      else {
        setOpenHelp(false)
      }
    })

  }, []);

  useEffect(() => {

    getTutoChecked().then(resp => {
      setChecked(!resp)
    })

  }, [openHelp]);


  useEffect(() => {

    if (listEmplDisplay.length >= 5) {
      setDisableBtn(true)
    }
    else {
      setDisableBtn(false)
    }

  }, [listEmplDisplay]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleSendInvitation = async () => {

    subscribe.username = subscribe.email
    const sub = { ...subscribe }
    sub.name = sub.name + " " + sub.familyName
    if (sub.name.length < 7) {
      //Thomas message
    }
    else {
      const a = await signupInvited(sub, session.id)
      if (a == 0) {
        setErrorMessage(true)
      }
      if (a == 1) {
        await setUserList()
        //var s = { ...subscribe }
        const s = {}
        s.name = ""
        s.familyName = ""
        s.email = ""
        s.username = ""
        s.role = "Task Manager"
        setSubscribe(s)
        setErrorMessage(false)
      }
    }
  }


  // const handleChange = (e) => {
  //   setEmail(e.target.value)
  // };

  const handleMenuClick = () => {
    toggleSidebar();
  };

  const handleOpenHelp = () => {
    setOpenHelp(!openHelp)
  }
  const handleOpenSessionTool = () => {
    setOpenSessionTool(!openSessionTool)
  }

  const handleClose = () => {
    updateTuto(!checked)
    setOpenHelp(!openHelp)
  }

  const handleTuto = () => {
    setChecked(!checked)
  }

  const handleDelete = async (id) => {
    await deleteUser(id)
    setUserList()
  }

  const changeTeam = (top) => {
    setTopic(top.teamName)
    console.log(top)
  }

  const handleChangeTextArea = e => {
    console.log(e.target.value)
    setTextToSupport(e.target.value)
  }

  const handleOpenSupportTool = () => {
    setOpenSupportTool(!openSupportTool)
  }

  const handleSendSupportMail = () => {
    const mailToSend = { text: textToSupport, topic: topic }
    sendSupportMail(mailToSend)
    handleOpenSupportTool()
  }
  useEffect(() => {

    console.log(topic)

  }, [topic]);



  const handleClickAvatar = () => { };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openHelp}
        onClose
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openHelp}>
          <>
            <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center", height: "100%"
            }}>
              <div className={classes.paperFiles} >
                <div style={{ display: "flex", justifyContent: "space-between", }}>
                  <p style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: 'center',
                    marginTop: "10px",
                    fontSize: "23px",
                    color: "#15c9b9",
                    fontFamily: "Ubuntu",
                    width: "90%",
                    marginLeft: "5%",
                    marginTop: "1vh"
                  }}>
                    {"How to get started with DPMO Colab :"}
                  </p>
                  <IconButton onClick={handleClose} style={{ cursor: "pointer" }}>
                    <Close />
                  </IconButton>
                </div>
                <div style={{ fontFamily: "Ubuntu", fontSize: "23px", textAlign: "center" }}>{title}</div>
                <br></br>
                <span style={{ color: "#C60800", fontFamily: "Ubuntu" }}>Please adjust your screen between 65% and 75% to get the perfect fit on your screen</span>
                <br></br><br></br>
                <SlideShow setTitle={setTitle} />
                <br></br>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <form style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                    <input
                      name="ShowOrNot"
                      type="checkbox"
                      checked={checked}
                      onChange={handleTuto} />
                    <label>
                      <FormattedMessage id="operationHeader.mes" defaultMessage="Do not show this anymore" />
                    </label>
                  </form>
                </div>

              </div>
            </div>
          </>
        </Fade>
      </Modal>
      {/* ==============Sessions=====================*/}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openSessionTool}
        onClose
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openSessionTool}>
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center", height: "100%"
          }}>
            <Paper className={classes.paperSession}>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: 'center',
                  marginTop: "10px",
                  fontSize: "23px",
                  color: "#15c9b9",
                  fontFamily: "Ubuntu",
                  width: "90%",
                  marginLeft: "5%",
                  marginTop: "1vh"
                }}>
                  <FormattedMessage id="operationHeader.session" defaultMessage="My Session" />
                </p>
                <IconButton onClick={handleOpenSessionTool}>
                  <Close />
                </IconButton>
              </div>
              <div style={{ display: "flex", flexDirection: "row", marginBottom: "2vh", borderBottom: "1px solid " }}>
                {session.name} <span style={{ marginLeft: "5px", marginRight: "5px" }}><FormattedMessage id="operationHeader.team" defaultMessage="Team (Max. 5 Users)" /></span> - {5 - listEmplDisplay.length} <span style={{ marginLeft: "5px" }}><FormattedMessage id="operationHeader.availableUsers" defaultMessage="users available" /></span>
              </div>
              {/* <p>{5 - listEmplDisplay.length} users available</p> */}
              <div style={{ textAlign: "center" }}>
                <div style={{ maxHeight: "37%", overflowY: "auto", marginBottom: "3vh" }}>
                  {listEmplDisplay.map((user) =>
                    <div className={classes.hover} style={{
                      flexDirection: "row",
                      display: "flex",
                      width: "50vh",
                      marginTop: "2vh",
                      height: "8vh",
                      paddingTop: "1vh",
                      paddingBottom: "1vh",
                      border: "1px solid grey",
                      paddingLeft: "1vh",
                      borderRadius: "10px",
                      borderBottom: "1px solid"
                    }}>
                      <div style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "row"
                      }}>
                        <div style={{
                          display: "flex",
                          alignItems: "center"
                        }}>
                          {user.profilePicture !== null ?
                            <>
                              <Avatar style={{
                                height: "6vh",
                                width: "6vh",
                                marginRight: "2vh"
                              }}
                                color="inherit"
                                aria-haspopup="true"
                                src={user.profilePicture.url}
                              />
                              <div style={{ fontSize: "15px" }}>{user.name} </div>
                            </>
                            :
                            <>
                              <Avatar style={{
                                height: "6vh",
                                width: "6vh",
                                marginRight: "2vh"
                              }}
                                color="inherit"
                                aria-haspopup="true"
                                src=""
                              />
                              <div style={{ fontSize: "15px" }}>{user.name} </div>
                            </>
                          }
                        </div>


                        {isSessionAdmin && user.id != currentUser.id &&
                          <Tooltip title="delete">
                            <IconButton onClick={() => { handleDelete(user.id) }}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <span style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: 'center',
                  fontSize: "15px",
                  color: "#15c9b9",
                  fontFamily: "Ubuntu",
                }}><FormattedMessage id="operationHeader.addUser" defaultMessage="Add new user info" /></span>
              </div>
              {isSessionAdmin &&
                <div>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="name"
                    label="First Name"
                    autoComplete="current-password"
                    onChange={handleChange}
                    value={subscribe.name}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="familyName"
                    label="Last Name"

                    autoComplete="current-password"
                    onChange={handleChange}
                    value={subscribe.familyName}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    onChange={handleChange}
                    value={subscribe.email}
                    style={{ marginBottom: "5vh" }}
                  />

                  {errorMessage &&
                    <p style={{ color: "red" }}> This email is invalid or already exist</p>
                  }

                  <div style={{ textAlign: "right" }}>
                    <Button
                      disabled={disableBtn}
                      variant="contained"
                      onClick={handleSendInvitation}
                      className={classes.submit}
                      id="guide_1"
                    >
                      <FormattedMessage id="operationHeader.sendInvitation" defaultMessage="Send Invitation" />
                    </Button>
                  </div>

                </div>
              }
            </Paper>
          </div>
        </Fade>
      </Modal>




      {/* =============================================== */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openSupportTool}
        onClose
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openSupportTool}>
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center", height: "100%"
          }}>
            <Paper className={classes.paperSupport}>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: 'center',
                  marginTop: "10px",
                  fontSize: "23px",
                  color: "#15c9b9",
                  fontFamily: "Ubuntu",
                  width: "90%",
                  marginLeft: "5%",
                  marginTop: "1vh"
                }}>
                  <FormattedMessage id="operationHeader.contactSupport" defaultMessage="Contact Support" />
                </p>
                <IconButton onClick={handleOpenSupportTool}>
                  <Close />
                </IconButton>
              </div>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <TeamSelectField
                  label={"Select Topic"}
                  options={[{ id: 1, teamName: "Report Bugs" }, { id: 2, teamName: "Suggestions" }, { id: 3, teamName: "Others" }]}
                  onChange={changeTeam}
                  selectedTeam={topic}
                />
              </div>
              <br />
              <label><FormattedMessage id="operationHeader.supportMessage" defaultMessage="Please write your message" />  : </label>
              <br />
              <br />
              <textarea name="message-support" rows="10" style={{ width: "100%" }} onChange={handleChangeTextArea} />
              <br />
              <br />
              <div style={{ textAlign: "right" }}>
                <Button
                  variant="contained"
                  onClick={handleSendSupportMail}
                  className={classes.submit}

                >
                  <FormattedMessage id="operationHeader.message" defaultMessage="Message" />
                </Button>
              </div>
            </Paper>
          </div>
        </Fade>
      </Modal>


      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div style={{ width: "15%", textAlign: "left", display: "flex", alignItems: "center" }}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="Menu"
              onClick={handleMenuClick}
            >
              <MenuIcon />
            </IconButton>
            {/*
            <div className={classes.divider} />
            <Hidden xsDown>
              <div className={classes.search}>
                <IconButton className={classes.searchIcon} onClick={handleClick}>
                  <SearchIcon />
                </IconButton>
                {open && (
                  <InputBase
                    placeholder="Search…"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput
                    }}
                    value={search}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "Search" }}
                  />
                )}
              </div>
            </Hidden>
            */}

            <Avatar
              alt="DPMO"
              src={dpmo}
              variant="square"
              className={classes.logo}
              id="tour_1"
            />
          </div>
          {/* <img
            alt="Free Trial"
            src={trial}
            className={classes.logoCompany}
            id="tour_1"
            height={40}
          /> */}
          <div style={{ width: "85%", textAlign: "center" }}>
            <h1 style={{ height: "40", color: "#009178", fontFamily: "Ubuntu" }}>Dpmo Colab</h1>
          </div>
          <div style={{ position: "absolute", right: "24px", display: "flex", alignItems: "center" }} id="tour_2">
            <Tooltip title="Contact Support">
              <IconButton onClick={handleOpenSupportTool} className={classes.button} style={{ marginRight: "1.5vh" }}>
                <HeadsetMicIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Help">
              <IconButton onClick={handleOpenHelp} className={classes.button} style={{ marginRight: "1.5vh" }}>
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Session Tool">
              <IconButton onClick={handleOpenSessionTool} className={classes.button} style={{ marginRight: "1.5vh" }}>
                <GroupRoundedIcon />
              </IconButton>
            </Tooltip>

            <LanguageFlag flag={flag} style={classes.flag} />
            {/*
            <AlertButton />
            <IconButton color="inherit" style={{ outline: "none" }}>
              
              <Badge badgeContent={0} color="secondary">
                ?
              </Badge>
              
            </IconButton>*/}
            <ProfilMenu member={member} />
            {/*<span>{member.role.name.split(" ").map((n, i, a) => i === 0 || i + 1 === a.length ? n[0] : null).join("")}</span> */}
          </div>
        </Toolbar>
      </AppBar >
    </>
  );
}

HeaderBar.propTypes = {};

const mapDispatchToProps = dispatch => {
  return {
    toggleSidebar: () => dispatch(toggleSidebar())
  };
};

export default connect(
  null,
  mapDispatchToProps
)(HeaderBar);
