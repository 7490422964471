import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import {getCurrentUser} from "../api/dpmoApiServices/UserServices"
import { makeStyles } from "@material-ui/core/styles";
import { setCurrentUser } from "../ducks/auth/action-creators";

const useStyles = makeStyles(theme => ({
  avatar: {
    color: "#fff",
    backgroundColor: "#F7905E",
    width: "60px",
    height: "60px",
    margin: "2px",
    marginLeft:"30px"

  },

}));

export default function AvatarIcon(props) {
  const classes = useStyles();
  const [currentUser, setCurrentUser] = useState({name:"Undefined",profilePicture:{}})
  const [avatarExist, setAvatarExist] = useState()

  
  useEffect(() => {
    async function fetchCriticalityList() {
      const res = await getCurrentUser();
      if(res.profilePicture!=null && res.profilePicture!=null){
        setAvatarExist(true)
      }
      setCurrentUser(res)
    }
    fetchCriticalityList();
  }, []);

  return (
    <>
      {avatarExist ?
        <>
          {props.profilPicture==undefined || props.profilPicture==null ?
            <Avatar className={classes.avatar} src={currentUser.profilePicture.url} {...props}/> 
          :
            <Avatar className={classes.avatar} src={props.profilPicture} {...props}/> 
          }
        </>
      
      :
        <Avatar className={classes.avatar} {...props}>
          {currentUser.name.split(" ").map((n)=>n[0]).join("")}
        </Avatar>
      }
    </>
  );
}
