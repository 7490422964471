import axios from 'axios';
import { dpmoApiUrl } from '../../constant/apiConfig'

/////////////////////////////////////////////////////
///En attendant 
/////////////////////////////////////////////////////

export async function getData() {
    const res = await axios.get(dpmoApiUrl + "PoliciesService/getAll")
    return await res.data
}

export async function changePolicies(data) {
    const res = await axios.post(dpmoApiUrl + "PoliciesService/modifyPolicies/", data)
    return await res.data
}

export async function deleteData(id) {
    axios.delete(dpmoApiUrl + "api", id)
    //return await res.data  
}

export async function createData(data) {
    const res = await axios.post(dpmoApiUrl + "PoliciesService/addPolicies", data)
    return await res.data
}


/////////////////////////////////////////////////////
///Companies
/////////////////////////////////////////////////////

export async function getAllCompanies() {
    const res = await axios.get(dpmoApiUrl + "api/v1/companies")
    return await res.data
}

export async function getCompany(id) {
    const res = await axios.get(dpmoApiUrl + "api/v1/companies/" + id)
    return await res.data
}

export async function addCompany(data, name) {
    const res = await axios.post(dpmoApiUrl + "api/v1/companies/" + name, data)
    return await res.data
}

export async function updateCompany(data, name) {
    const res = await axios.put(dpmoApiUrl + "api/v1/companies/" + name, data)
    return await res.data
}

export async function postAvatar(avatar) {
    const res = await axios.post(dpmoApiUrl + "api/v1/uploadAvatar", avatar)
    return await res.data
}

export async function getUserSession() {
    const res = await axios.get(dpmoApiUrl + "api/v1/companies/currentUser")
    return await res.data
}

export async function getSessionUsers(sessionId) {
    const res = await axios.get(dpmoApiUrl + "api/v1/companies/users/" + sessionId)
    return await res.data
}

export async function encodeSession(sessionId) {
    const res = await axios.get(dpmoApiUrl + "api/v1/companies/encode/" + sessionId)
    return await res.data
}

export async function getSessionFiles(sessionId) {
    const res = await axios.get(dpmoApiUrl + "api/v1/companies/sessionSize/" + sessionId)
    return await res.data
}

/////////////////////////////////////////////////////
///OBS
/////////////////////////////////////////////////////


export async function getOBS() {
    const res = await axios.get(dpmoApiUrl + "api/obs")
    return await res.data
}


export async function deleteOBS(id) {
    axios.delete(dpmoApiUrl + "api/obs/" + id)
    //return await res.data  
}

export async function createOBS(data) {
    const res = await axios.post(dpmoApiUrl + "api/obs", data)
    return await res.data
}
/////////////////////////////////////////////////////
///WBS
/////////////////////////////////////////////////////
export async function getWBS() {
    const res = await axios.get(dpmoApiUrl + "api/wbs"
    )
    return await res.data

}
export async function deleteWBS(id) {
    axios.delete(dpmoApiUrl + "api/wbs/" + id)
}

export async function createWBS(data) {
    const res = await axios.post(dpmoApiUrl + "api/wbs", data)
    return await res.data
}
/////////////////////////////////////////////////////
///ABS
/////////////////////////////////////////////////////
export async function getABS() {
    const res = await axios.get(dpmoApiUrl + "api/abs")
    return await res.data

}
export async function deleteABS(id) {
    axios.delete(dpmoApiUrl + "api/abs/" + id)
}

export async function createABS(data) {
    const res = await axios.post(dpmoApiUrl + "api/abs", data)
    return await res.data
}

/////////////////////////////////////////////////////
///PBS
/////////////////////////////////////////////////////
export async function getPBS() {
    const res = await axios.get(dpmoApiUrl + "api/pbs")
    return await res.data

}
export async function deletePBS(id) {
    axios.delete(dpmoApiUrl + "api/pbs/" + id)
}

export async function createPBS(data) {
    const res = await axios.post(dpmoApiUrl + "api/pbs", data)
    return await res.data
}

/////////////////////////////////////////////////////
///Location
/////////////////////////////////////////////////////

export async function getLocation() {
    const res = await axios.get(dpmoApiUrl + "api/locations")
    return await res.data
}

export async function deleteLocation(id) {
    axios.delete(dpmoApiUrl + "api/locations/" + id)
}

export async function createLocation(data) {
    const res = await axios.post(dpmoApiUrl + "api/locations", data)
    return await res.data
}


/////////////////////////////////////////////////////
///SensitiveLocation
/////////////////////////////////////////////////////

export async function getSensitiveLocation() {
    const res = await axios.get(dpmoApiUrl + "api/sensitiveLocations")
    return await res.data
}

export async function deleteSensitiveLocation(id) {
    axios.delete(dpmoApiUrl + "api/sensitiveLocations/" + id)
}

export async function createSensitiveLocation(data) {
    const res = await axios.post(dpmoApiUrl + "api/sensitiveLocations", data)
    return await res.data
}

/////////////////////////////////////////////////////
///SensitiveContractor
/////////////////////////////////////////////////////

export async function getSensitiveContractor() {
    const res = await axios.get(dpmoApiUrl + "api/sensitiveContractors")
    return await res.data
}

export async function deleteSensitiveContractor(id) {
    axios.delete(dpmoApiUrl + "api/sensitiveContractors/" + id)
}

export async function createSensitiveContractor(data) {
    const res = await axios.post(dpmoApiUrl + "api/sensitiveContractors", data)
    return await res.data
}


/////////////////////////////////////////////////////
///Memberships
/////////////////////////////////////////////////////


export async function getMyMembership() {
    const res = await axios.get(dpmoApiUrl + "api/memberships/me")
    return await res.data

}

export async function getMembersByRole(role) {
    const res = await axios.get(dpmoApiUrl + "api/memberships/role/" + role)
    return await res.data

}

export async function getMembers() {
    const res = await axios.get(dpmoApiUrl + "api/memberships")
    return await res.data

}

export async function getCompanyAdminFromCompany(companyName) {
    const res = await axios.get(dpmoApiUrl + "api/memberships/admin/" + companyName)
    return await res.data

}
export async function getAllMembersFromCompany(companyId) {
    const res = await axios.get(dpmoApiUrl + "api/company/" + companyId + "/memberships")
    return await res.data

}

export async function createMember(data) {
    const res = await axios.post(dpmoApiUrl + "api/company/addUser", data)
    return await res.data

}


/////////////////////////////////////////////////////
///Portfolio
/////////////////////////////////////////////////////

export async function getPortfolios() {
    const res = await axios.get(dpmoApiUrl + "api/portfolio")
    return await res.data
}
export async function deletePortfolio(id) {
    axios.delete(dpmoApiUrl + "api/portfolio/" + id)
}

export async function createPortfolio(data) {
    const res = await axios.post(dpmoApiUrl + "api/portfolio", data)
    return await res.data
}


/////////////////////////////////////////////////////
///Pipeline
/////////////////////////////////////////////////////

export async function getPipelines() {
    const res = await axios.get(dpmoApiUrl + "api/pipeline")
    return await res.data
}
export async function deletePipeline(id) {
    axios.delete(dpmoApiUrl + "api/pipeline/" + id)
}

export async function createPipeline(data) {
    const res = await axios.post(dpmoApiUrl + "api/pipeline", data)
    return await res.data
}

/////////////////////////////////////////////////////
///Projects
/////////////////////////////////////////////////////

export async function getAllProjects(portfolioId) {
    const res = await axios.get(dpmoApiUrl + "api/portfolio/" + portfolioId + "/allProjects")
    return await res.data
}

export async function getAllProjectsBySession(portfolioId, sessionId) {
    const res = await axios.get(dpmoApiUrl + "api/portfolio/" + portfolioId + "/" + sessionId + "/allProjects")
    return await res.data
}

export async function createProject(portfolioId, data, companyId) {
    const res = await axios.post(dpmoApiUrl + "api/portfolio/" + portfolioId + "/" + companyId + "/projects", data)
    return await res.data
}

export async function createBidding(tender) {
    const res = await axios.post(dpmoApiUrl + "api/project/create/bidding", tender)
    return await res.data
}

export async function getProjectById(Id) {
    const res = await axios.get(dpmoApiUrl + "api/project/" + Id)
    return await res.data
}

export async function deleteProject(id) {
    axios.delete(dpmoApiUrl + "api/project/" + id + "/delete")
}

export async function getProjectByComplexity(complexity, origin, id) {
    const res = await axios.get(dpmoApiUrl + "api/portfolio/" + id + "/project/complexity?complexity=" + complexity + "&origin=" + origin)
    return await res.data
}

export async function getProjectByStatus(status, origin, id) {
    const res = await axios.get(dpmoApiUrl + "api/portfolio/" + id + "/project/status?status=" + status + "&origin=" + origin)
    return await res.data
}

export async function getProjectByStatusAndComplexity(status, complexity, origin, id) {
    const res = await axios.get(dpmoApiUrl + "api/portfolio/" + id + "/project/filtre?status=" + status + "&complexity=" + complexity + "&origin=" + origin)
    return await res.data
}

export async function updateProject(project, status) {
    const res = await axios.put(dpmoApiUrl + "api/project?status=" + status, project)
    return await res.data
}



/**********************************  CBS ********************************************/

export async function getCBS() {
    const res = await axios.get(dpmoApiUrl + "api/cbs")
    return await res.data

}
export async function deleteCBS(id) {
    axios.delete(dpmoApiUrl + "api/cbs/" + id)
}

export async function createCBS(data) {
    const res = await axios.post(dpmoApiUrl + "api/cbs", data)
    return await res.data
}



/**********************************  Cluster PnL ********************************************/

export async function getClusterPnL() {
    const res = await axios.get(dpmoApiUrl + "api/clusterPnL")
    return await res.data

}
export async function deleteClusterPnL(id) {
    axios.delete(dpmoApiUrl + "api/clusterPnL/" + id)
}

export async function createClusterPnL(data) {
    const res = await axios.post(dpmoApiUrl + "api/clusterPnL", data)
    return await res.data
}



/**********************************  Product line ********************************************/

export async function getProductLine() {
    const res = await axios.get(dpmoApiUrl + "api/productLines")
    return await res.data

}
export async function deleteProductLine(id) {
    axios.delete(dpmoApiUrl + "api/productLines/" + id)
}

export async function createProductLine(data) {
    const res = await axios.post(dpmoApiUrl + "api/productLines", data)
    return await res.data
}
