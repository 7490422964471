import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

import SidebarContent from "./sidebarContent.js";

import { drawerWidth } from "../../constant/webappConstants.js";
import { connect } from "react-redux";
import { togglePortfolio } from "../../ducks/navigation";
import { Scrollbars } from "react-custom-scrollbars";


const portfolios = [
  { id: 1, name: "portfolio1" },
  { id: 2, name: "portfolio2" },
  { id: 3, name: "portfolio3" }
];

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    color: "#fff"
  },
  drawer: {
    width: "15vw",
    flexShrink: 0
  },
  drawerPaper: {
    width: "15vw",
    background:
      "linear-gradient(0deg, rgb(0, 160, 115) 0%, rgb(0, 140, 122) 100%)"
  },
}));

const SidebarWithoutConnect = props => {
  const classes = useStyles();
  const { togglePortfolio, history, navigation } = props;

  const [portfolio, setPortfolio] = React.useState("My Portfolio");

  const handlePortfolioSelect = portfolio => {
    setPortfolio(portfolio.name);
    togglePortfolio(portfolio);
    history.push("/portfolio/" + portfolio.id + "/dashboard/financial");
  };
  const handleClose = () => { };

  return (


    <SwipeableDrawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={navigation.sidebar.isOpen}
      classes={{
        paper: classes.drawerPaper
      }}
      onClose={handleClose}
      onOpen={handleClose}
    >
      <div className={classes.toolbar} />
      <Scrollbars autoHide>
        {/*<PortfolioChoice
          name={portfolio}
          portfolios={portfolios}
          handleSelect={handlePortfolioSelect}
        />*/}
        <SidebarContent currentPortfolio={navigation.portfolio} />
      </Scrollbars>
    </SwipeableDrawer>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    togglePortfolio: portfolio => dispatch(togglePortfolio(portfolio))
  };
};

const mapStateToProps = state => {
  return {
    navigation: state.navigation
  };
};

const Sidebar = connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarWithoutConnect);

export default Sidebar;
