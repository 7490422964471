import axios from 'axios';
import { dpmoApiUrl } from '../../constant/apiConfig'

export async function getCurrentUser() {
    const res = await axios.get(dpmoApiUrl + "api/user/me")
    return await res.data
}
export async function getUserById(id) {
    const res = await axios.get(dpmoApiUrl + "api/user/" + id)
    return await res.data
}
export async function getUserByName(name) {
    const res = await axios.get(dpmoApiUrl + "api/user/getByName/" + name)
    return await res.data
}
export async function updateCurrentUser(data) {
    const res = await axios.put(dpmoApiUrl + "api/user/me", data)
    return await res.data
}
export async function getUserByUsernameOrEmail(username) {
    const res = await axios.get(dpmoApiUrl + "api/user/" + username)
    return await res.data
}
export async function checkPassword(password) {
    const res = await axios.post(dpmoApiUrl + "api/user/checkPassword", password)
    return await res.data
}
export async function updateUserPassword(user) {
    const res = await axios.put(dpmoApiUrl + "api/user/update/password", user)
    return await res.data
}
export async function postAvatar(avatar) {
    const res = await axios.post(dpmoApiUrl + "api/uploadAvatar", avatar)
    return await res.data
}
export async function getMyAvatar() {
    const res = await axios.get(dpmoApiUrl + "api/avatar/me")
    return await res.data
}

export async function archiveUser(id) {
    const res = await axios.put(dpmoApiUrl + "api/user/archive/" + id)
    return await res.data
}

export async function getAllUsers() {
    const res = await axios.get(dpmoApiUrl + "api/user")
    return await res.data
}

export async function getAllProfilePicture() {
    const res = await axios.get(dpmoApiUrl + "api/users/profilePicture")
    return await res.data
}

export async function getTeamByIdTask(taskId) {
    const res = await axios.get(dpmoApiUrl + "api/user/action/teamTask/" + taskId)
    return await res.data
}

export async function getTutoChecked() {
    const res = await axios.get(dpmoApiUrl + "api/user/tuto")
    return await res.data
}

export async function updateTuto(checked) {
    const res = await axios.put(dpmoApiUrl + "api/user/updateTuto/" + checked)
    return await res.data
}

export async function deleteUser(id) {
    const res = await axios.delete(dpmoApiUrl + "api/user/delete/" + id)
    return await res.data
}