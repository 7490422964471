import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

/*const Auth = {
  isAuthenticated: true,
  authenticate() {
    this.isAuthenticated = true;
  },
  signout() {
    this.isAuthenticated = false;
  },
  getAuth() {
    return this.isAuthenticated;
  }
};*/

const ProtectedRoute = ({ component: Component, props, ...rest }) => {
  const { isAuthenticated } = rest.auth;
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/signIn",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

export default connect(mapStateToProps)(ProtectedRoute);
