import axios from 'axios';
import { dpmoApiUrl } from '../../constant/apiConfig'


export async function sendInvitationEmail(email) {
    const res = await axios.get(dpmoApiUrl + "api/mail/invitation/" + email)
    return await res.data
}

export async function sendSupportMail(data) {
    const res = await axios.post(dpmoApiUrl + "api/auth/supportMail", data)
    return await res.data
}