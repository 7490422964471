import { Route, Switch } from "react-router";
import React from "react";

import WebappRoute from "./appRoutes"
import Connexion from "../view/connexion";
import SignUp from "../view/signUp";
import ProtectedRoute from "./ProtectedRoute"

export default function ApplicationRouter(store) {
  return (
    <Switch>
      <Route path="/signIn" component={Connexion} />
      <Route path="/signUp" component={SignUp} />
      <ProtectedRoute component={WebappRoute} />
    </Switch>
  );
}
