import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import LanguageButton from "../components/header/LanguageButton";

const useStyles = makeStyles({});

export default function SimpleDialog({ match, history }) {
  const classes = useStyles();

  const handleClose = e => {
    e.stopPropagation();
    history.goBack();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={true}
      maxWidth="md"
    >
      <LanguageButton />
    </Dialog>
  );
}

SimpleDialog.propTypes = {};
