import axios from 'axios';
import { dpmoApiUrl } from '../../constant/apiConfig'

export const portfolioId = 1;

export async function calculationModulePipeline() {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/calculationModulePipeline")
    return await res.data
}

///////////////////////////////////////////////////////////////////////////
//////////////////////////////////PORTFOLIO////////////////////////////////
///////////////////////////////////////////////////////////////////////////

// Financial

export async function getPortfolioCashByPortfolioId(portfolioId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/portfolio/portfolioCash/getPortfolioCashByPortfolioId/" + portfolioId)
    return await res.data
}

export async function getPortfolioSalesByPortfolioId(portfolioId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/portfolio/portfolioSales/getPortfolioSalesByPortfolioId/" + portfolioId)
    return await res.data
}

export async function getPortfolioMarginsByPortfolioId(portfolioId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/portfolio/portfolioMargins/getPortfolioMarginsByPortfolioId/" + portfolioId)
    return await res.data
}

export async function getPortfolioContingenciesByPortfolioId(portfolioId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/portfolio/portfolioContingencies/getPortfolioContingenciesByPortfolioId/" + portfolioId)
    return await res.data
}

export async function getPortfolioCostsByPortfolioId(portfolioId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/portfolio/portfolioCosts/getPortfolioCostsByPortfolioId/" + portfolioId)
    return await res.data
}


export async function getPortfolioNPOHByPortfolioId(portfolioId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/portfolio/portfolioNPOH/getPortfolioNPOHByPortfolioId/" + portfolioId)
    return await res.data
}


// Operational


export async function getPortfolioRisksByPortfolioId(portfolioId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/portfolio/portfolioRisks/getPortfolioRisksByPortfolioId/" + portfolioId)
    return await res.data
}


export async function getPortfolioOpportunitiesByPortfolioId(portfolioId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/portfolio/portfolioOpportunities/getPortfolioOpportunitiesByPortfolioId/" + portfolioId)
    return await res.data
}


export async function getPortfolioCriticalIssuesByPortfolioId(portfolioId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/portfolio/portfolioCriticalIssues/getPortfolioCriticalIssuesByPortfolioId/" + portfolioId)
    return await res.data
}


export async function getPortfolioQualityEventsByPortfolioId(portfolioId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/portfolio/portfolioQualityEvents/getPortfolioQualityEventsByPortfolioId/" + portfolioId)
    return await res.data
}


// Planning


export async function getPortfolioWorkloadByPortfolioId(portfolioId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/portfolio/portfolioWorkload/getPortfolioWorkloadByPortfolioId/" + portfolioId)
    return await res.data
}


export async function getPortfolioUtilisationRateByPortfolioId(portfolioId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/portfolio/portfolioUtilisationRate/getPortfolioUtilisationRateByPortfolioId/" + portfolioId)
    return await res.data
}


export async function getPortfolioMilestonesByPortfolioId(portfolioId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/portfolio/portfolioMilestones/getPortfolioMilestonesByPortfolioId/" + portfolioId)
    return await res.data
}


export async function getPortfolioCriticalGatesByPortfolioId(portfolioId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/portfolio/portfolioCriticalGates/getPortfolioCriticalGatesByPortfolioId/" + portfolioId)
    return await res.data
}


///////////////////////////////////////////////////////////////////////////
//////////////////////////////////PROJECT//////////////////////////////////
///////////////////////////////////////////////////////////////////////////



// Financial

export async function getProjectCashByProjectId(projectId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/project/projectCash/getProjectCashByProjectId/" + projectId)
    return await res.data
}

export async function getProjectSalesByProjectId(projectId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/project/projectSales/getProjectSalesByProjectId/" + projectId)
    return await res.data
}

export async function getProjectContingenciesByProjectId(projectId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/project/projectContingencies/getProjectContingenciesByProjectId/" + projectId)
    return await res.data
}

export async function getProjectCostsByProjectId(projectId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/project/projectCosts/getProjectCostsByProjectId/" + projectId)
    return await res.data
}

export async function getProjectMarginsByProjectId(projectId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/project/projectMargins/getProjectMarginsByProjectId/" + projectId)
    return await res.data
}

export async function getProjectNPOHByProjectId(projectId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/project/projectNPOH/getProjectNPOHByProjectId/" + projectId)
    return await res.data
}


// Operational

export async function getProjectCriticalIssuesByProjectId(projectId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/project/projectCriticalIssues/getProjectCriticalIssuesByProjectId/" + projectId)
    return await res.data
}

export async function getProjectOpportunitiesByProjectId(projectId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/project/projectOpportunities/getProjectOpportunitiesByProjectId/" + projectId)
    return await res.data
}

export async function getProjectRisksByProjectId(projectId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/project/projectRisks/getProjectRisksByProjectId/" + projectId)
    return await res.data
}

// Planning

export async function getProjectCriticalGatesByProjectId(projectId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/project/projectCriticalGates/getProjectCriticalGatesByProjectId/" + projectId)
    return await res.data
}

export async function getProjectMilestonesByProjectId(projectId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/project/projectMilestones/getProjectMilestonesByProjectId/" + projectId)
    return await res.data
}

export async function getProjectUtilisationRateByProjectId(projectId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/project/projectUtilisationRate/getProjectUtilisationRateByProjectId/" + projectId)
    return await res.data
}

export async function getProjectWorkloadByProjectId(projectId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/project/projectWorkload/getProjectWorkloadByProjectId/" + projectId)
    return await res.data
}


///////////////////////////////////////////////////////////////////////////
//////////////////////////////////WP///////////////////////////////////////
///////////////////////////////////////////////////////////////////////////



// Financial

export async function getWPCashByWpId(wpId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/wp/wpCash/getWPCashByWpId/" + wpId)
    return await res.data
}

export async function getWPSalesByWpId(wpId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/wp/wpSales/getWPSalesByWpId/" + wpId)
    return await res.data
}

export async function getWPContingenciesByWpId(wpId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/wp/wpContingencies/getWPContingenciesByWpId/" + wpId)
    return await res.data
}

export async function getWPMarginsByWpId(wpId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/wp/wpMargins/getWPMarginsByWpId/" + wpId)
    return await res.data
}

export async function getWPNPOHByWpId(wpId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/wp/wpNPOH/getWPNPOHByWpId/" + wpId)
    return await res.data
}

export async function getWPCostsByWpId(wpId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/wp/wpCosts/getWPCostsByWpId/" + wpId)
    return await res.data
}

//Operational

export async function getWPCriticalIssuesByWpId(wpId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/wp/wpCriticalIssues/getWPCriticalIssuesByWpId/" + wpId)
    return await res.data
}

export async function getWPOpportunitiesByWpId(wpId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/wp/wpOpportunities/getWPOpportunitiesByWpId/" + wpId)
    return await res.data
}

export async function getWPRisksByWpId(wpId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/wp/wpRisks/getWPRisksByWpId/" + wpId)
    return await res.data
}


// Planning

export async function getWPCriticalGatesByWpId(wpId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/wp/wpCriticalGates/getWPCriticalGatesByWpId/" + wpId)
    return await res.data
}

export async function getWPMilestonesByWpId(wpId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/wp/wpMilestones/getWPMilestonesByWpId/" + wpId)
    return await res.data
}

export async function getWPUtilisationRateByWpId(wpId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/wp/wpUtilisationRate/getWPUtilisationRateByWpId/" + wpId)
    return await res.data
}

export async function getWPWorkloadByWpId(wpId) {
    const res = await axios.get(dpmoApiUrl + "api/calculationModule/wp/wpWorkload/getWPWorkloadByWpId/" + wpId)
    return await res.data
}

// critical issues service

export async function getCriticalIssueByWP(wpId) {
    const res = await axios.get(dpmoApiUrl + "api/workpackage/" + wpId + "/criticalIssues")
    return await res.data
}



