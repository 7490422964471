import { Link, Redirect } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import Avatar from "../avatar";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DateRange from "@material-ui/icons/DateRange";
import PowerIcon from "@material-ui/icons/PowerSettingsNew";
import Profil from "@material-ui/icons/AccountCircle";
import SettingIcon from "@material-ui/icons/Settings";
import React from "react";
import { deleteCurrentUser } from "../../ducks/auth/action-creators";
import { useEffect } from "react";
import { signOut } from "../../api/dpmoApiServices/AuthServices"
import setAuthorizationToken from "../../config/authorizationHeader";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  paper: {
    marginRight: theme.spacing(2)
  }
}));

function MenuListComposition(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [logout, setLogout] = React.useState(false);
  const anchorRef = React.useRef(null)


  function handleToggle() {
    setOpen(prevOpen => !prevOpen);
  }

  function handleClose(event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  }

  const handleDisconnect = () => {
    props.disconnectUser();
    setAuthorizationToken()
    signOut()
    setLogout(true);
  };


  // const setupCompany = () => {
  //   return (
  //     <MenuItem onClick={handleClose} component={Link} to="/companySetting">
  //       <ListItemIcon>
  //         <SettingIcon />
  //       </ListItemIcon>
  //       <Typography variant="inherit">Company setting</Typography>
  //     </MenuItem>
  //   );
  // };

  if (logout) {
    return <Redirect push to="/signIn" />;
  }
  return (
    <div className={classes.root}>
      <div>
        <Avatar
          color="inherit"
          ref={anchorRef}
          aria-owns={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          style={{ outline: "none", cursor: "pointer" }}
        />

        <Menu
          open={open}
          anchorEl={anchorRef.current}
          transition
          disablePortal
          placement="bottom-start"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          PaperProps={{
            style: {
              padding: 0,
              right: "5px",
              transform: "translateX(-65px) translateY(+45px)"
            }
          }}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList>
              <div style={{ paddingLeft: "10px", textAlign: "justify" }}>
                <div>{props.member.userSummary.name}</div>
                <div>{props.member.company.name}</div>
                <div>{props.member.role.name}</div>
              </div>
              <Divider />


              <MenuItem onClick={handleClose} component={Link} to="/profil">
                <ListItemIcon>
                  <Profil />
                </ListItemIcon>
                <Typography variant="inherit"><FormattedMessage id="operationLabel.profile" defaultMessage="Profil"/></Typography>
              </MenuItem>

              {props.member.role.name == "DPMO Admin" &&
                <MenuItem onClick={handleClose} component={Link} to="/DPMOSetting"
                /*to={{
              pathname: `/test`,
              // this is the trick!
                state: { modal: true }
            }}*/>
                  <ListItemIcon>
                    <SettingIcon />
                  </ListItemIcon>
                  <Typography variant="inherit"><FormattedMessage id="operationLabel.DPMOsetting" defaultMessage="DPMO Setting"/></Typography>
                </MenuItem>
              }

              {/*<MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <DateRange />
                </ListItemIcon>
                <Typography variant="inherit">My Calendar</Typography>
        </MenuItem>*/}

              {/* {props.member.role.name == "Company Admin" &&
               // setupCompany()
              } */}

              <MenuItem onClick={handleDisconnect}>
                <ListItemIcon>
                  <PowerIcon />
                </ListItemIcon>
                <Typography color="inherit"><FormattedMessage id="operationLabel.logout" defaultMessage="Log out"/></Typography>
              </MenuItem>
            </MenuList>

          </ClickAwayListener>
        </Menu>
      </div>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    disconnectUser: () => dispatch(deleteCurrentUser())
  };
};

export default connect(
  null,
  mapDispatchToProps
)(MenuListComposition);
