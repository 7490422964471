import React, { useState } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { OutlinedInput as MuiOutlinedInput } from "@material-ui/core";
import { InputLabel as MuiSelectNativeInput } from "@material-ui/core";

export default function TeamSelectField({label, options, onChange, selectedTeam, ...rest}) {
    const [team, setTeam] = useState("");
    const [selected, setSelected] = useState(false);
    const [width, setWidth] = useState("35%");
  
    const handleChange = (event) => {
      setWidth("")
      setSelected(true)
      setTeam(event.target.value);
    };

    // const valueSelect = () => {
    //   if (selectedTeam !== null ) {
    //     setSelected(true)
    //     return selectedTeam
    //   } else {
    //     return team
    //   }
    // };

    const OutlinedInput = withStyles((theme) => ({
        notchedOutline: {
          border: "none",
        },
      }))(MuiOutlinedInput);

    const useStyles = makeStyles(theme => ({
        select: {
            color: "white"
          },
        icon: { color: "white" },

    }));

    const classes = useStyles();

    return (
        
            <FormControl variant="outlined" style={{ width: width, marginBottom:"7px", width:"130px" }}>
                <div style={{ paddingBottom:"7px" }}><InputLabel style={{ color:"white", fontSize:"15px" }} shrink={false}>{!selected && 'Select topic'}</InputLabel></div>
                <Select
                    value={team}
                    onChange={handleChange}
                    classes={{
                        select: classes.select,
                        icon: classes.icon,
                      }}
                    input={<OutlinedInput/>}
                    style={{ backgroundColor: "#008f79", borderRadius: "40px", boxShadow: "3px 3px 5px grey", height:"40px", fontSize:"15px" }}
                >
                    {options.map(option => (
                        <MenuItem key={option.id} value={option.teamName} onClick={() => onChange(option)}>
                            {option.teamName}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            // {/* <TextField label={label} select {...rest} style={{
            //     backgroundColor: "#99ffff", borderRadius: "20px", color: "#666666", boxShadow: "3px 3px 5px grey", width:"25%"
            // }} > */}
            
            // {/* </TextField> */}
        
    );
};