import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import ExpandLess from "@material-ui/icons/ChevronRight";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import WpSubitem from "./wpSubItem"
import { Link } from "react-router-dom";



const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    padding: 0,
  },
  nested: {
    padding: 0,
    paddingLeft: theme.spacing(4)
  },
  white:{
    color:"#fff"
  }
}));

export default function NestedList(props) {
  const { title, children } = props;
  const classes = useStyles();
  const [open, setopen] = React.useState(false);

  function handleClick() {
    setopen(!open);
  }

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
   
      <ListItem button onClick={handleClick}>
        {open ? <ExpandMore className={classes.white} /> : <ExpandLess  className={classes.white}/>}
        <ListItemText primary={title} className={classes.white}/>
      </ListItem>
      
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </List>
  );
}