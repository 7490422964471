import {getWBS} from "../../api/dpmoApiServices/CompanyServices";
import React, { useState, useEffect } from "react";


const initialState = [
  {
    id: 1,
    name: "Sourcing"
  },
  {
    id: 2,
    name: "Site Management"
  },
  {
    id: 3,
    name: "Construction"
  }
]; 

export const obsList = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
