import axios from 'axios'
import { dpmoApiUrl } from '../../constant/apiConfig'

export async function getAllGroupsOfUser() {
    const res = await axios.get(dpmoApiUrl + "api/user/instantMessageGroups")
    return res.data
}

export async function getAllGroupsOfUserChunk(chunk) {
    const res = await axios.get(dpmoApiUrl + "api/user/instantMessageGroups/" + chunk)
    return res.data
}

export async function getLastMessage(idGroup) {
    const res = await axios.get(dpmoApiUrl + "api/instantMessages/group" + idGroup + "/lastMessage")
    return res.data
}

export async function getAllOtherUsersInGroup(idGroup) {
    const res = await axios.get(dpmoApiUrl + "api/instantMessageGroup/users" + idGroup)
    return res.data
}

export async function getAllUsersNotInGroup(idGroup) {
    const res = await axios.get(dpmoApiUrl + "api/instantMessages/group" + idGroup + "/notIncluded")
    return res.data
}

export async function getMessageByGroup(idGroup) {
    const res = await axios.get(dpmoApiUrl + "api/instantMessages/group" + idGroup)
    return res.data
}

export async function postNewMessage(data) {
    const res = await axios.post(dpmoApiUrl + "api/instantMessages/new", data)
    return res.data
}

export async function addNewGroup(data) {
    const res = await axios.post(dpmoApiUrl + "api/instantMessages/newGroup", data)
    return res.data
}

export async function getFavorite(idGroup) {
    const res = await axios.get(dpmoApiUrl + "api/instantMessages/group" + idGroup + "/favorite")
    return res.data
}

export async function toggleFavorite(idGroup) {
    const res = await axios.get(dpmoApiUrl + "api/instantMessages/group" + idGroup + "/toggleFavorite")
    return res.data
}

export async function getMessagesPending(idGroup) {
    const res = await axios.get(dpmoApiUrl + "api/instantMessages/group" + idGroup + "/messagesPending")
    return res.data
}

export async function addMessagesPending(idGroup) {
    const res = await axios.get(dpmoApiUrl + "api/instantMessages/group" + idGroup + "/addMessagesPending")
    return res.data
}

export async function resetMessagesPending(idGroup) {
    const res = await axios.get(dpmoApiUrl + "api/instantMessages/group" + idGroup + "/resetMessagesPending")
    return res.data
}

export async function getAllMessagesPending() {
    const res = await axios.get(dpmoApiUrl + "api/instantMessages/getAllMessagesPending")
    return res.data
}

export async function deleteGroup(idGroup) {
    const res = await axios.get(dpmoApiUrl + "api/instantMessages/group" + idGroup + "/delete")
    return res.data
}

export async function setLastMessage(idGroup, message) {
    const res = await axios.put(dpmoApiUrl + "api/instantMessages/group" + idGroup + "/setLastMessage", message)
    return res.data
}

export async function deleteMessage(id) {
    const res = await axios.delete(dpmoApiUrl + "api/instantMessages/" + id + "/delete")
    return res.data
}

export async function getFilesOfInstantMessagesGroup(id) {
    const res = await axios.get(dpmoApiUrl + "api/file/instantMessagesGroup/" + id)
    return res.data
}

export async function getJunctionMessageUserIdGroup(idGroup) {
    const res = await axios.get(dpmoApiUrl + "api/instantMessages/junction/group" + idGroup)
    return res.data
}
