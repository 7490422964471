import axios from 'axios';
import { dpmoApiUrl } from '../../constant/apiConfig'

/****************************************Work Package*****************************************/

export async function getAllWorkpackageFromPortfolio(portfolioId) {
    const res = await axios.get(dpmoApiUrl + "api/portfolio/" + 1 + "/workpackages")
    return await res.data
}

export async function getAllWorkpackageFromPhase(phase) {
    const res = await axios.get(dpmoApiUrl + "api/project/phase" + phase + "/workpackages")
    return await res.data
}

export async function getAllWPNames() {
    const res = await axios.get(dpmoApiUrl + "api/workpackage/allNames")
    return await res.data
}

export async function getAllWorkpackageFromProject(projectId) {
    const res = await axios.get(dpmoApiUrl + "api/project/" + projectId + "/workpackages")
    return await res.data
}

export async function getAllWorkpackageFromWorkpackage(wpId) {
    const res = await axios.get(dpmoApiUrl + "api/workpackage/" + wpId + "/workpackages")
    return await res.data
}

export async function getWorkpackageById(wpId) {
    const res = await axios.get(dpmoApiUrl + "api/workpackages/" + wpId)
    return await res.data
}

export async function createWorkpackage(projectId, data) {
    const res = await axios.post(dpmoApiUrl + "api/project/" + projectId + "/workpackages", data)
    return await res.data
}
export async function createWorkpackageLevel(wpId, data) {
    const res = await axios.post(dpmoApiUrl + "api/workpackage/" + wpId + "/workpackages", data)
    return await res.data
}

export async function updateWorkpackage(wpId, data) {

    const res = await axios.put(dpmoApiUrl + "api/workpackages/" + wpId, data)
    return await res.data
}

export async function getWPByStatus(statusTenders, id) {
    const res = await axios.get(dpmoApiUrl + "api/project/" + id + "/workpackage/status?status=" + statusTenders);
    return await res.data;
}

/**************************************Deliverable***************************************/

export async function createDeliverable(wpId, data) {
    const res = await axios.post(dpmoApiUrl + "api/workpackage/" + wpId + "/delivrables", data)
    return await res.data
}

export async function getDeliverableByWpId(wpId) {
    const res = await axios.get(dpmoApiUrl + "api/workpackage/" + wpId + "/delivrables")
    return await res.data
}

export async function getDeliverableById(id) {
    const res = await axios.get(dpmoApiUrl + "api/deliverable/" + id)
    return await res.data
}

export async function getAllDeliverables() {
    const res = await axios.get(dpmoApiUrl + "api/workpackage/delivrables")
    return await res.data
}
export async function getAllDeliverablesByProject(projectId) {
    const res = await axios.get(dpmoApiUrl + "api/project/" + projectId + "/delivrables")
    return await res.data
}

export async function updateDelivrable(id, data) {

    const res = await axios.put(dpmoApiUrl + "api/delivrables/" + id, data)
    return await res.data
}
/**************************************Milestone***************************************/

export async function getAllMilestones() {
    const res = await axios.get(dpmoApiUrl + "api/project/milestones")
    return await res.data
}