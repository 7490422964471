import React, { useState, useEffect, createRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ListSubItems from "./listSubItem";
import { Link } from "react-router-dom";
import { getAllAction } from "../../api/dpmoApiServices/TaskServices";
import { getAllNotifsTasks, getTasksNotified } from "../../api/dpmoApiServices/NotificationServices"
import GraphIcon from "@material-ui/icons/Assessment";
import TableChart from "@material-ui/icons/TableChart";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import Description from "@material-ui/icons/Description";
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import EventIcon from "@material-ui/icons/Event";
import Work from "@material-ui/icons/Work";
import Group from "@material-ui/icons/Group";
import WpSubItem from "./wpSubItem";
import { injectIntl } from "react-intl";
import { calculationModulePipeline } from "../../api/dpmoApiServices/calculationModuleServices";
import Badge from '@material-ui/core/Badge';
import store from "../../ducks/store"
import { getAllProjects } from "../../api/dpmoApiServices/CompanyServices";
import { getMyMembership } from "../../api/dpmoApiServices/CompanyServices"
import { getAllMessagesPending } from "../../api/dpmoApiServices/InstantMessageServices"
import { getAllDiscussionPending, getAllDiscussions } from "../../api/dpmoApiServices/DiscussionsServices"
import ChatIcon from '@material-ui/icons/Chat';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SockJsClient from "react-stomp";
import { dpmoApiUrl } from "../../constant/apiConfig"
import { getCurrentUser } from "../../api/dpmoApiServices/UserServices"
import { getAlertsPending } from "../../api/dpmoApiServices/AlertsServices"
import useSound from 'use-sound';
import mp3File from "../../sound/beep.mp3"
// import Notification from "../Notification/Notification"
// import 'react-notifications/lib/notifications.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactNotifications from 'react-browser-notifications';
import dpmoLogo from "../../img/dpmo.png"
import DonutSmallIcon from '@material-ui/icons/DonutSmall';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedIn from '@material-ui/icons/LinkedIn';
import Twitter from '@material-ui/icons/Twitter';
import WhatsApp from '@material-ui/icons/WhatsApp';
import Instagram from '@material-ui/icons/Instagram';




const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: "10vh"
  },
  nested: {
    padding: 0,
    paddingLeft: theme.spacing(4)
  },
  white: {
    color: "#fff"
  }

}));

function SidebarContent(props) {
  const { currentPortfolio, intl } = props;
  const id = currentPortfolio.id;
  const classes = useStyles();

  const [menu, setMenu] = React.useState(window.location.hash.split("/")[1])

  const [openProject, setopenProject] = React.useState(true);
  const [menuSelected, setMenuSelected] = React.useState(menu);
  const [dataProject, setDataProject] = useState([]);
  var authorisation = store.getState().auth.authorisation;
  const [messagesPending, setMessagesPending] = useState(0)
  const [discussionPending, setDiscussionPending] = useState(0)
  const [alertPending, setAlertPending] = useState(0)
  const [pendingTasks, setPendingTasks] = useState(0)

  const showNotifications = () => {
    // If the Notifications API is supported by the browser
    // then show the notification
    if (notifRef.supported()) notifRef.show();
  }

  const handleClickNotification = (event) => {
    // Do something here such as
    // console.log("Notification Clicked") OR
    // window.focus() OR
    // window.open("http://www.google.com")

    // Lastly, Close the notification
    notifRef.close(event.target.tag);
  }


  //Error message of tasks 
  const fetchNotif = async () => {

    //Task delayed task notif
    const allTasks = await getAllAction()
    var overdueTask = 0
    for (let i = 0; i < allTasks.length; i++) {
      if (new Date(allTasks[i].endDate) > new Date() && allTasks[i].status !== "CLOSED") {
        overdueTask = overdueTask + 1
      }
    }
    if (overdueTask > 0) {
      toast.warning(overdueTask + " tasks delayed", {
        position: "bottom-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: 'black-background',
        bodyClassName: "grow-font-size",
        progressClassName: 'fancy-progress-bar'
      });
    }

    //delayed discussion notif
    var overdueDiscussion = 0
    const allDiscussion = await getAllDiscussions()
    for (let i = 0; i < allDiscussion.length; i++) {
      if (new Date(allDiscussion[i].deadline > new Date())) {
        overdueDiscussion = overdueDiscussion + 1
      }
    }
    if (overdueDiscussion > 0) {
      toast.info(overdueTask + " discussion delayed", {
        position: "bottom-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: 'black-background',
        bodyClassName: "grow-font-size",
        progressClassName: 'fancy-progress-bar'
      });
    }
  }

  const handleSelectedAndClearStorage = (selected) => {
    if (selected == "tasks" && window.location.href.includes("tasks")) {
      // setSelected(selected)
      window.location.reload()
    }
    else {
      window.localStorage.removeItem("newItem1")
      window.localStorage.removeItem("newItem2")
      setSelected(selected)
    }
    // setSelected(selected)
  }

  useEffect(() => {
    showNotifications()
    fetchNotif()
  }, [])

  const [membership, setMembership] = React.useState({
    role: {
      name: ""
    }
  })
  var clientRef = createRef()
  var clientRefTask = createRef()
  var notifRef = createRef()
  const [play] = useSound(mp3File);


  const handleClick = () => {
    setopenProject(!openProject);
  };

  const projectItem = item => {
    return (
      <ListItem
        key={item.id}
        button
        className={classes.nested}
        component={Link}
        to={"/portfolio/1/dashboard/financial/cash"}
      >
        <ListItemText primary={item.name} className={classes.white} />
      </ListItem>
    );
  };

  const getSelected = current => {
    if (menu === current) {
      return { backgroundColor: "#feb636", color: "black" };
    }
    else {
      return { color: "white" };
    }
  };

  const setSelected = (current) => {
    setMenu(current)
  }

  const doCaculationModulePipeline = () => {
    const response = calculationModulePipeline();

  }

  const updatePending = async (msg) => {
    const pendingMessages = await getAllMessagesPending()
    if (pendingMessages !== messagesPending && !window.location.href.includes("social")) {
      play()
    }
    setMessagesPending(pendingMessages)
    const pendingDiscussion = await getAllDiscussionPending()
    if (pendingDiscussion !== discussionPending && !window.location.href.includes("myDiscussions")) {
      play()
    }
    setDiscussionPending(pendingDiscussion)

    const pendingTask = await getAllNotifsTasks()
    setPendingTasks(pendingTask)

  }

  const openApplication = (url) => {
    window.open(url)
  }

  useEffect(() => {


    getAlertsPending().then(res => {
      setAlertPending(res)
      if (res > 0) {
        toast.error(res + " alerts", {
          position: "bottom-right",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: 'black-background',
          bodyClassName: "grow-font-size",
          progressClassName: 'fancy-progress-bar'
        });
      }
    })
    getAllNotifsTasks().then(res => {
      if (res > 0) {
        toast.warning(res + " new tasks", {
          position: "bottom-right",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: 'black-background',
          bodyClassName: "grow-font-size",
          progressClassName: 'fancy-progress-bar'
        });
      }
    })

    getCurrentUser().then(res => {
      var url = dpmoApiUrl + "api/alerts/stream/sidebar/" + res.id
      var es = new EventSource(url);

      es.onmessage = e => {


        //Faire appel api qui compte le nombre de alert pending
        getAlertsPending().then(res => {
          setAlertPending(res)
        })
      }

      es.onopen = e => {

      }

      es.onerror = e => {
      }

    })
    async function fetchCriticalityList() {
      const response = await getAllProjects(currentPortfolio.id);
      setDataProject(response);
      const res = await getMyMembership()
      setMembership(res[0])
      updatePending()
    }
    fetchCriticalityList();

  }, []);

  return (
    <>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.root}
      >
        <SockJsClient
          url={dpmoApiUrl + "ws"}
          topics={["/topics/pendingMessage"]}
          onMessage={(msg) => updatePending(msg)}
          ref={(client) => {
            clientRef = client;
          }}
        />

        <ReactNotifications
          onRef={ref => (notifRef = ref)} // Required
          title="DPMO" // Required
          body="DPMO browser notification"
          icon={dpmoLogo}
          tag="abcdef"
          timeout="20000"
          onClick={event => handleClickNotification(event)}
        />
        {/*
        <ListItem style={getSelected(1)} onClick={() => setSelected(1)} button component={Link} to={"/scope"}>
          <ListItemIcon className={classes.white}>
            <Scope />
          </ListItemIcon>
          <ListItemText
            primary={intl.formatMessage({ id: "My Scope" })}
            className={classes.white}
          />
        </ListItem>
      

        <ListItem style={getSelected("accountAdmin")} onClick={() => setSelected("accountAdmin")} button component={Link} to={"/companyAdmin"}>
          <ListItemIcon className={classes.white}>
          </ListItemIcon>
          <ListItemText
            primary={intl.formatMessage({ id: "sidebar.companyAdmin" })}
            className={classes.white}
          />
        </ListItem>
  */}

        {membership.role.name == "DPMO Admin" && (
          <ListItem style={getSelected("compagny")} onClick={() => { setSelected("compagny") }} button component={Link} to={"/DPMOSetting"}>
            <ListItemIcon className={classes.white}>
              <GraphIcon />
            </ListItemIcon>

            <ListItemText
              primary={intl.formatMessage({ id: "sidebar.company_settings" })}
              className={classes.white}
            />
          </ListItem>
        )}

        {membership.role.name == "DPMO Admin" && (
          <ListItem style={getSelected(8)} onClick={() => { handleSelectedAndClearStorage(8) }} button component={Link} to={"/DPMOSuperAdmin"}>
            <ListItemIcon className={classes.white}>
              <GraphIcon />
            </ListItemIcon>

            <ListItemText
              primary={intl.formatMessage({ id: "sidebar.super_admin" })}
              className={classes.white}
            />
          </ListItem>
        )}

        {/*
        {authorisation.Access_Monitoring_Portfolio && (
          <>
            <ListItem style={getSelected("portfolio")} onClick={() => { handleSelectedAndClearStorage("portfolio"); doCaculationModulePipeline(); }} button component={Link} to={"/portfolio/" + id + "/dashboard"}>
              <ListItemIcon className={classes.white}>
                <GraphIcon />
              </ListItemIcon>

              <ListItemText
                primary={intl.formatMessage({ id: "sidebar.execution_monitoring" })}
                className={classes.white}
              />
            </ListItem>
          </>
        )}
        {/*
            <Collapse in={openProject} timeout="auto" unmountOnExit>
              <ListSubItems title={intl.formatMessage({ id: "sidebar.project" })}>
                {dataProject.map(item => (
                  <ListItem
                    key={item.id}
                    button
                    className={classes.nested}
                    component={Link}
                    to={"/portfolio/" + id + "/projectDashboard/" + item.id}
                  >
                    <ListItemText primary={item.name} className={classes.white} />
                  </ListItem>))}
              </ListSubItems>

              <ListSubItems title={intl.formatMessage({ id: "sidebar.wp" })}>
                {dataProject.map(item => (
                  <WpSubItem
                    key={item.id}
                    title={item.name}
                    workpackages={item.workpackage}
                    projectId={item.id}
                    pfId={currentPortfolio.id}
                  />
                ))}
              </ListSubItems>
            </Collapse>
          </>

        )}

        */}


        {/* {authorisation.Access_Tendering_Project && (
          <ListItem style={getSelected("tendering")} onClick={() => setSelected("tendering")} button component={Link} to={"/tendering"}>
            <ListItemIcon className={classes.white}>
              <TableChart />
            </ListItemIcon>
            <ListItemText
              primary={intl.formatMessage({ id: "sidebar.sales_monitoring" })}
              className={classes.white}
            />
          </ListItem>
        )}

        {(authorisation.Access_Bidding_Project) && (
          <>
            <ListItem style={getSelected("sales")} onClick={() => handleSelectedAndClearStorage("sales")} button component={Link} to={"/sales/tenders"}>
              <ListItemIcon className={classes.white}>
                <GroupWorkIcon />
              </ListItemIcon>
              <ListItemText
                primary={intl.formatMessage({ id: "sidebar.sales_operation" })}
                className={classes.white}
              />
            </ListItem>
          </>
        )}

        {(authorisation.Access_Baselining_Project || authorisation.Access_Execution_Project) && (
          <>
            <ListItem style={getSelected("execution")} onClick={() => handleSelectedAndClearStorage("execution")} button component={Link} to={"/execution/baselining"}>
              <ListItemIcon className={classes.white}>
                <Work />
              </ListItemIcon>
              <ListItemText
                primary={intl.formatMessage({ id: "sidebar.execution_operation" })}
                className={classes.white}
              />
            </ListItem>
          </>
        )} */}


        <ListItem style={getSelected("tasks")} onClick={() => handleSelectedAndClearStorage("tasks")} button component={Link} to={"/tasks"}>
          <ListItemIcon style={getSelected("tasks")}>
            <Badge color="secondary" badgeContent={pendingTasks}></Badge>

            <EventIcon />
          </ListItemIcon>
          <ListItemText
            primary={intl.formatMessage({ id: "sidebar.tasks" })}
          />
        </ListItem>


        <ListItem style={getSelected("social")} onClick={() => handleSelectedAndClearStorage("social")} button component={Link} to={"/social"}>
          <ListItemIcon style={getSelected("social")}>
            <Badge color="secondary" badgeContent={messagesPending}></Badge>
            <ChatIcon />

          </ListItemIcon>
          <ListItemText
            primary={intl.formatMessage({ id: "sidebar.socialIM" })}
          />
        </ListItem>

        <ListItem style={getSelected("myDiscussions")} onClick={() => handleSelectedAndClearStorage("myDiscussions")} button component={Link} to={"/myDiscussions"}>
          <ListItemIcon style={getSelected("myDiscussions")}>
            <Badge color="secondary" badgeContent={discussionPending}></Badge>
            <QuestionAnswerIcon />
          </ListItemIcon>
          <ListItemText
            primary={intl.formatMessage({ id: "sidebar.my_discussions" })}
          />
        </ListItem>

        {/* <ListItem style={getSelected("myAlerts")} onClick={() => setSelected("myAlerts")} button component={Link} to={"/myAlerts"}>
          <ListItemIcon className={classes.white}>
            <Badge color="secondary" badgeContent={alertPending}></Badge>
            <NotificationsIcon />
          </ListItemIcon>
          <ListItemText
            primary={intl.formatMessage({ id: "sidebar.my_alerts" })}
            className={classes.white}
          />
        </ListItem> */}


        <ListItem style={getSelected("myFiles")} onClick={() => handleSelectedAndClearStorage("myFiles")} button component={Link} to={"/myFiles"}>
          <ListItemIcon style={getSelected("myFiles")}>
            <FileCopyIcon />
          </ListItemIcon>
          <ListItemText
            primary={intl.formatMessage({ id: "sidebar.my_files" })}
          />
        </ListItem>


        {/* {(membership.role.name == "Project Manager" || membership.role.name == "Company Admin") && (
          <ListItem style={getSelected("myCoach")} onClick={() => setSelected("myCoach")} button component={Link} to={"/myCoach"}>
            <ListItemIcon className={classes.white}>
              <RecordVoiceOver />
            </ListItemIcon>
            <ListItemText
              primary={intl.formatMessage({ id: "sidebar.my_coach" })}
              className={classes.white}
            />
          </ListItem>
        )} */}

        <ListItem style={getSelected("myTeams")} onClick={() => handleSelectedAndClearStorage("myTeams")} button component={Link} to={"/myTeams"}>
          <ListItemIcon className={classes.white}>
            <SupervisedUserCircleIcon />
          </ListItemIcon>
          <ListItemText
            primary={intl.formatMessage({ id: "sidebar.my_resources" })}
            className={classes.white}
          />
        </ListItem>

        {/* <ListItem style={getSelected("myCertifications")} onClick={() => setSelected("myCertifications")} button component={Link} to={"/myCertifications"}>
          <ListItemIcon className={classes.white}>
            <SupervisedUserCircleIcon />
          </ListItemIcon>
          <ListItemText
            primary={intl.formatMessage({ id: "sidebar.my_certifications" })}
            className={classes.white}
          />
      </ListItem>*/}

        {/* <ToastContainer /> */}





      </List>

      <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "center", height: "68%", padding: "16px" }}>
        <Tooltip title="Facebook">
          <IconButton className={classes.white} style={{ height: "30px" }} component={Link} onClick={() => openApplication("//www.facebook.com/")} >
            <FacebookIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Twitter">
          <IconButton className={classes.white} style={{ height: "30px" }} component={Link} onClick={() => openApplication("//www.twitter.com/")} >
            <Twitter />
          </IconButton>
        </Tooltip>
        <Tooltip title="Instagram">
          <IconButton className={classes.white} style={{ height: "30px" }} component={Link} onClick={() => openApplication("//www.instagram.com/")} >
            <Instagram />
          </IconButton>
        </Tooltip>
        <Tooltip title="LinkedIn">
          <IconButton className={classes.white} style={{ height: "30px" }} component={Link} onClick={() => openApplication("//www.linkedin.com/")} >
            <LinkedIn />
          </IconButton>
        </Tooltip>
        <Tooltip title="Whatsapp">
          <IconButton className={classes.white} style={{ height: "30px" }} component={Link} onClick={() => openApplication("//web.whatsapp.com")} >
            <WhatsApp />
          </IconButton>
        </Tooltip>
      </div>
    </>

  );
}
export default injectIntl(SidebarContent);
