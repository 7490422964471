import {} from "./action-types.js";

const initialState = {
  currency:"€"
}

export const projectDetail = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
