import { createStore, applyMiddleware, compose } from "redux";
import { combineReducers } from "redux";
import thunk from "redux-thunk";

import { authReducer } from "./auth";
import { navigationReducer } from "./navigation";
import { userListReducer } from "./userList";
import { wbsListReducer } from "./wbsList";
import { obsListReducer } from "./obsList";
import { projectDetailReducer } from "./currentProjectDetail";
import { companyDetailReducer } from "./companyDetail";

const combineReducer = combineReducers({
  auth: authReducer,
  navigation: navigationReducer,
  userList:userListReducer,
  wbsList:wbsListReducer,
  obsList:obsListReducer,
  projectDetail:projectDetailReducer,
  companyDetail:companyDetailReducer
});

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  combineReducer,
  storeEnhancers(applyMiddleware(thunk))
);

export default store;
