import React, { Component, lazy, Suspense } from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";

import Header from "../components/header/header";
import SidebarV2 from "../components/sidebar/Sidebar";
//import SidebarV2 from "../components/basicComponent";
import Content from "../components/Content";

//import WpExecution from "../view/workpackageExecution/workpackageExecution";
//import Project from "../view/projectBaselining/project";
//import Tasks from "../view/tasksManagement/tasks";
import Parameter from "../view/userParameter";
//import Dashboard from "../view/dashboard/dashboard";
//import MonitoringWP from "../view/dashboard/dashboardWP";
//import MonitoringProject from "../view/dashboard/dashboardProject";
//import Sales from "../view/tendering/sales";
//import TenderVisu from "../view/tenderVisu/tendering";
//import PortfolioDetail from "../view/portfolioDetail";
//import CompanySetup from "../components/pages/companyPage/accountSetup.js";
//import TestPage from "../view/common/pageDeTest"
import ChatBotMS from "../components/chatbot/chatbotMS"
import ProtectedRoute from "../routes/ProtectedRoute";
//import Table from "../view/Table/table";
//import TableBidding from "../view/TableTender/table"
//import ActionPlanNew from "../view/tasksManagement/actionPlanNew";
//import UnderConstruction from "../view/common/underConstruction";
//import SocialIM from "../components/social/socialIM/socialIM";
//import DPMOSetup from "../components/pages/companyPage/DPMOSetup";
//import NewCompany from "../view/companySetup/CompanyNew"
//import Profil from "../components/pages/companyPage/profil";
//import DPMOSuperAdmin from "../components/pages/companyPage/DPMOSuperAdmin";
//import Files from "../view/common/files"
//import myDiscussions from "../components/social/myDiscussions/myDiscussions";
//import AIRisks from "../view/airisks"
//import MyTeamsSetup from "../components/myTeams/myTeamsSetup"




const socialIm = lazy(() => import("../components/social/socialIM/socialIM"));
const aiRisks = lazy(() => import("../view/airisks"));
const wpExecution = lazy(() => import("../view/workpackageExecution/workpackageExecution"));
const project = lazy(() => import("../view/projectBaselining/project"));
const sales = lazy(() => import("../view/tendering/sales"));
const table = lazy(() => import("../view/Table/table"));
const tenderVisu = lazy(() => import("../view/tenderVisu/tendering"));
const dashboard = lazy(() => import("../view/dashboard/dashboard"));
const monitoringProject = lazy(() => import("../view/dashboard/dashboardProject"));
const monitoringWp = lazy(() => import("../view/dashboard/dashboardWP"));
const tasks = lazy(() => import("../view/tasksManagement/tasks"));
const parameter = lazy(() => import("../view/userParameter"));
const profil = lazy(() => import("../components/pages/companyPage/profil"));
const companySetup = lazy(() => import("../components/pages/companyPage/accountSetup.js"));
const newCompany = lazy(() => import("../view/companySetup/CompanyNew"));
const dpmoSetup = lazy(() => import("../components/pages/companyPage/DPMOSetup"));
const dpmoSuperAdmin = lazy(() => import("../components/pages/companyPage/DPMOSuperAdmin"));
const portfolioDetail = lazy(() => import("../view/portfolioDetail"));
const testPage = lazy(() => import("../view/common/pageDeTest"));
const tableBidding = lazy(() => import("../view/TableTender/table"));
const actionPlanNew = lazy(() => import("../view/tasksManagement/actionPlanNew"));
const underConstruction = lazy(() => import("../view/common/underConstruction"));
const myTeamsSetup = lazy(() => import("../components/myTeams/myTeamsSetup"));
const myCertificationsSetup = lazy(() => import("../components/myCertif/myCertifications"));
const myDiscussions = lazy(() => import("../components/social/myDiscussions/myDiscussions"));
const files = lazy(() => import("../view/common/files"));
const myAlerts = lazy(() => import("../components/social/myAlerts/myAlerts"))
const myCoach = lazy(() => import("../components/social/myCoach/myCoachNav"))
const CompanyAdmin = lazy(() => import("../components/companyAdmin/companyAdmin"))

// const myCoach = lazy(() => import("../components/social/myCoach/myCoachNav"))
const TimeTrackingItem = lazy(() => import("../components/myTeams/TimeTrackingItem"))

const routes = [
  {
    path: "/companyAdmin",
    exact: false,
    main: CompanyAdmin
  },
  {
    path: "/AIRisks",
    exact: false,
    main: aiRisks
  },
  {
    path: "/workpackage/:workpackageId",
    exact: false,
    main: wpExecution
  },
  {
    path: "/project/:projectId",
    exact: false,
    main: project
  },

  {
    path: "/sales",
    exact: false,
    main: sales
  },
  {
    path: "/execution",
    exact: false,
    main: table
  },
  {
    path: "/tendering",
    exact: false,
    main: tenderVisu
  },
  {
    path: "/portfolio/:portfolioId/dashboard",
    exact: false,
    main: dashboard
  },
  {
    path: "/portfolio/:portfolioId/projectDashboard/:projectId",
    exact: false,
    main: monitoringProject
  },
  {
    path: "/portfolio/:portfolioId/WPDashboard/:wpId",
    exact: false,
    main: monitoringWp
  },
  {
    path: "/tasks",
    exact: false,
    main: tasks
  },
  {
    path: "/test",
    exact: false,
    main: parameter
  },
  {
    path: "/profil",
    exact: false,
    main: profil
  },
  {
    path: "/companySetting",
    exact: false,
    main: companySetup
  },
  {
    path: "/DPMOSetting/new",
    exact: false,
    main: newCompany
  },
  {
    path: "/DPMOSetting",
    exact: false,
    main: dpmoSetup
  },
  {
    path: "/DPMOSuperAdmin",
    exact: false,
    main: dpmoSuperAdmin
  },
  {
    path: "/portfolio/:portfolioId/detail",
    exact: false,
    main: portfolioDetail
  },
  {
    path: "/testPage",
    exact: false,
    main: testPage
  },
  {
    path: "/portfolio/:portfolioId/tableBidding",
    exact: false,
    main: tableBidding
  },
  {
    path: "/workpackage/:workpackageId/execution/actionPlan",
    exact: false,
    main: actionPlanNew
  },
  {
    path: "/scope",
    exact: false,
    main: underConstruction
  },
  {
    path: "/myCertifications",
    exact: false,
    main: myCertificationsSetup
  },
  {
    path: "/myTeams",
    exact: false,
    main: myTeamsSetup
  },
  {
    path: "/myDiscussions",
    exact: false,
    main: myDiscussions
  },
  {
    path: "/social",
    exact: false,
    main: socialIm
  },
  {
    path: "/myFiles",
    exact: false,
    main: files
  },
  {
    path: "/myAlerts",
    exact: false,
    main: myAlerts
  },
  {
    path: "/myCoach",
    exact: false,
    main: myCoach
  },
  {
    path: "/timeTracking/:objectType/:itemId/:teamId",
    exact: false,
    main: TimeTrackingItem
  },
];

function Routes(props) {
  const [open, setOpen] = React.useState(true);
  const [previousLocation, setValue] = React.useState(props.location);

  const redirect = link => {
    props.history.push(link);
  };

  const handlers = {
    GO_TASKS: event => redirect("/tasks"),
    GO_TENDER: event => redirect("/portfolio/:portfolioId/tendering"),
    GO_DASHBOARD: event => redirect("/portfolio/:portfolioId/dashboard")
  };

  const openMenu = () => {
    setOpen(!open);
  };

  let { location } = props;

  let isModal = !!(location.state && location.state.modal) //&& previousLocation !== location)

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <CssBaseline />
      <Header handleMenuClick={openMenu} />
      <SidebarV2 draw={open} />
      <Content sidebar={open}>
        <Switch location={isModal ? previousLocation : location}>
          <Redirect
            from="/"
            to="/tasks"
            exact
          />
          {routes.map((route, index) => (
            <ProtectedRoute
              key={index}
              path={route.path}
              exact={route.exact}
              component={route.main}
            />
          ))}
        </Switch>
        {isModal ? <Route path="/test" component={Parameter} /> : null}
      </Content >
      {/* <ChatBotMS /> */}
    </div >
  );
}

export default Routes;
