import axios from 'axios';
import { dpmoApiUrl } from '../../constant/apiConfig';

/****************************     Critical issues    ******************************/


export async function getAllRoles() {
    const res = await axios.get(dpmoApiUrl + "api/roles")
    return await res.data;
};

export async function getAllAuthorisation() {
    const res = await axios.get(dpmoApiUrl + "api/authorisations")
    return await res.data;
};

export async function getAuthorisationCurrentUser(id) {
    const res = await axios.get(dpmoApiUrl + "api/rolesAuthorisations/current/"+id)
    return await res.data;
};

export async function getAllAuthorisationCurrentUser() {
    const res = await axios.get(dpmoApiUrl + "api/rolesAuthorisations/current/all")
    return await res.data;
};

export async function getAllRolesAuthorisations() {
    const res = await axios.get(dpmoApiUrl + "api/rolesAuthorisations")
    return await res.data;
};

export async function updateRoleAuthorisations(data) {
    const res = await axios.put(dpmoApiUrl + "api/role", data)
    return await res.data
};

export async function addRole(data) {
    const res = await axios.post(dpmoApiUrl + "api/role", data)
    return await res.data
};

