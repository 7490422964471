import axios from "axios";
import { dpmoApiUrl } from '../../constant/apiConfig'

export async function getAllAlerts() {
    const res = await axios.get(dpmoApiUrl + "api/alerts/all")
    return res.data
}

export async function getAlertsPending() {
    const res = await axios.get(dpmoApiUrl + "api/alerts/getAllALertsPending")
    return res.data
}

export async function setAccepted(id) {
    const res = await axios.get(dpmoApiUrl + "api/alerts/accept/" + id)
    return res.data
}

export async function getAllTenders() {
    const res = await axios.get(dpmoApiUrl + "api/tenders")
    return res.data
}

export async function getProject(id) {
    const res = await axios.get(dpmoApiUrl + "api/project/" + id)
    return res.data
}
export async function getTender(id) {
    const res = await axios.get(dpmoApiUrl + "api/tenders/" + id)
    return res.data
}

export async function updateArchive(id) {
    const res = await axios.get(dpmoApiUrl + "api/alerts/archive/" + id)
    return res.data
}

export async function setDecision(id, decision) {
    const res = await axios.get(dpmoApiUrl + "api/alerts/" + decision + "/" + id)
    return res.data
}

