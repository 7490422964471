import React from "react";
import JoyRide from "react-joyride";




// Tour steps
const TOUR_STEPS = [
  {
    target: "#guide_0",
    content: "Welcome to DPMO, let me show you my amazing features.",
    disableBeacon: true, // This makes the tour to start automatically without click
    run: true
  },
  {
    target: "#guide_1",
    content: "Enter your email and your password to begin."
  },
];


// Initial state for the tour component
const INITIAL_STATE = {
  key: new Date(), // This field makes the tour to re-render when we restart the tour
  run: false,
  continuous: true, // Show next button
  loading: false,
  stepIndex: 0, // Make the component controlled
  steps: TOUR_STEPS
};


// Tour component
const Tour = () => {
  /* // Reducer will manage updating the local state
  const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      //start the tour
      case "START":
        return{... state, run:true};
      //reste to 0th step
      case "RESET":
        return {... state, stepIndex: 0 };
      //Stop the tour
      case "STOP":
        return{... state, run:false};
      //Update the steps for next / back button click
      case "NEXT_OR_PREV":
        return {...state, ...action.payload };
      // Restart the tour - reset go to 1st step, restart new tour
      case "RESTART":
        return {
          ... state,
          stepnndex: 0,
          run: true,
          loading: false,
          key: new Date()
        };
        default :
        return state;
    }
  // TODO: Implement reducer  
  };
*/
  return (
    <>
      <JoyRide 
      steps={TOUR_STEPS} 
      continuous={true} 
      showSkipButton={true}
      locale={{
        last: "End tour",
        skip: "Close tour"
      }}
      styles={{
        tooltipContainer : {
          textAlign: "left"
        },
        buttonNext: {
          backgroundColor: "#398f7a"
        },
        buttonBack: {
          marginright: 10
        }
      }}
      />
    </>
  );
};

export default Tour;