import {getWBS} from "../../api/dpmoApiServices/CompanyServices";
import React, { useState, useEffect } from "react";

const CriticalIssue = () => {
  
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchCriticalityList() {
      const response = await getWBS();
      setData(response);
      setLoading(false);
    }
    fetchCriticalityList();
  }, []);

}



const initialState = [
  {
    id: 1,
    name: "Maintenance"
  },
  {
    id: 2,
    name: "Engineering"
  },
  {
    id: 3,
    name: "Depot"
  },
  {
    id: 4,
    name: "Renovation"
  },
  {
    id: 4,
    name: "Overhaul"
  }
]; 

export const wbsList = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
