import React from 'react'
import ReactCountryFlag from "react-country-flag"
import { makeStyles } from "@material-ui/core/styles";



export default function LanguageFlag(props) {

    return (

        <ReactCountryFlag
            style={{ width: 50, height: 25 }}
            countryCode={props.flag}
            svg
            title={props.flag}
        />
    );

}
