import { SET_PORTFOLIO, TOOGLE_SIDEBAR } from "./action-types.js";

const initialState = {
  portfolio: {
    id: 1
  },
  sidebar: {
    isOpen: true
  }
};

export const navigation = (state = initialState, action) => {
  switch (action.type) {
    case SET_PORTFOLIO:
      return {
        ...state,
        portfolio: action.portfolio
      };
    case TOOGLE_SIDEBAR:
      const test = state;
      return {
        ...state,
        sidebar: { isOpen: !test.sidebar.isOpen }
      };
    default:
      return state;
  }
};
