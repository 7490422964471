import axios from 'axios';
import { dpmoApiUrl } from '../../constant/apiConfig'

export async function getTasks() {
    const res = await axios.get(dpmoApiUrl + "api/task/action")
    return await res.data
}

export async function getTask(id) {
    const res = await axios.get(dpmoApiUrl + "api/tasks/" + id)
    return await res.data
}

export async function getTasksUser(userId) {
    const res = await axios.get(dpmoApiUrl + "api/task/actions/user/" + userId)
    return await res.data
}


export async function updateTask(task, id) {
    const res = await axios.put(dpmoApiUrl + "api/tasks/" + id, task)
    return await res.data
}

export async function archiveTask(id) {
    const res = await axios.put(dpmoApiUrl + "api/task/action/" + id + "/archive")
    return await res.data
}

export async function unarchiveTask(id) {
    const res = await axios.put(dpmoApiUrl + "api/task/action/" + id + "/unarchive")
    return await res.data
}

///////////////////////////////Action Plan/////////////////////////

export async function getAllAction() {
    const res = await axios.get(dpmoApiUrl + "api/task/action")
    return await res.data
}
export async function getArchivedAction() {
    const res = await axios.get(dpmoApiUrl + "api/task/action/archive")
    return await res.data
}
export async function getActionById(id) {
    const res = await axios.get(dpmoApiUrl + "api/task/action/" + id)
    return await res.data // sylvain
}

export async function getAllActionsByProject(id) {
    const res = await axios.get(dpmoApiUrl + "api/task/action/project/" + id)
    return await res.data
}

export async function getAllActionsByTender(id) {
    const res = await axios.get(dpmoApiUrl + "api/task/action/tender/" + id)
    return await res.data
}

export async function getAllActionTeamView() {
    const res = await axios.get(dpmoApiUrl + "MyTeamService/allTasksLinked")
    return await res.data
}

export async function getAllActionNames() {
    const res = await axios.get(dpmoApiUrl + "api/task/allNames")
    return await res.data
}

export async function getAllDeliverableNames() {
    const res = await axios.get(dpmoApiUrl + "api/deliverable/allNames")
    return await res.data
}

export async function createAction(data) {
    const res = await axios.post(dpmoApiUrl + "api/task/action", data)
    return await res.data
}
export async function deleteAction(data) {
    const res = await axios.post(dpmoApiUrl + "api/task/action/delete", data)
    // await axios.post(dpmoApiUrl + "api/task/action", res)
    return await res.data
}

export async function createTaskTeam(userList) {
    const res = await axios.post(dpmoApiUrl + "api/task/action", userList)
    return await res.data
}

export async function updateAction(id, data) {
    const res = await axios.put(dpmoApiUrl + "api/task/action/" + id, data)
    return await res.data
}

export async function updateActionUserList(id, list) {
    const res = await axios.put(dpmoApiUrl + "api/task/action/userList/" + id, list)
    return await res.data
}

export async function findLinkedProject(scopeId, taskId) {
    const res = await axios.get(dpmoApiUrl + "api/task/projectLinked/" + "Project" + "/" + scopeId + "/" + taskId)
    return await res.data
}

export async function getOwnersByTasks(tasksList) {
    const res = await axios.post(dpmoApiUrl + "api/task/action/owner", tasksList)
    return await res.data
}


////////

export async function exportTasksExcel(data) {
    const res = await axios.post(dpmoApiUrl + "api/task/action/exportExcel", data)
    return await res.data
}

export async function importTasks(data) {
    const res = await axios.post(dpmoApiUrl + "api/task/action/import", data)
    return await res.data
}

export async function exportTasksCsv(data) {
    const res = await axios.post(dpmoApiUrl + "api/task/action/exportCsv", data)
    return await res.data
}


export async function getTasksByRoleFilter(filter) {
    const res = await axios.get(dpmoApiUrl + "api/task/action/roleFilter/" + filter)
    return await res.data
}
