import React from "react";
import { IntlProvider, addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import Es from "react-intl/locale-data/es";
import Fr from "react-intl/locale-data/fr";
import De from "react-intl/locale-data/de";

import esTranslation from "../i18n/locales/es.json";
import enTranslation from "../i18n/locales/en.json";
import frTranslation from "../i18n/locales/fr.json";
import deTranslation from "../i18n/locales/de.json";

addLocaleData([...en, ...Es, ...Fr, ...De]);

const Context = React.createContext();

class IntlProviderWrapper extends React.Component {
  constructor(...args) {
    super(...args);

    this.toogleLanguage = language => {
      switch (language) {
        case "GB":
          this.setState({ locale: "en", messages: enTranslation });
          break;
        case "Es":
          this.setState({ locale: "Es", messages: esTranslation });
          break;
        case "Fr":
          this.setState({ locale: "Fr", messages: frTranslation });
          break;
        case "De":
          this.setState({ locale: "De", messages: deTranslation });
          break;
      }
    };
    // pass everything in state to avoid creating object inside render method (like explained in the documentation)
    this.state = {
      locale: this.langue,
      messages: enTranslation,
      toogleLanguage: this.toogleLanguage
    };
  }

  componentWillMount() {
    this.defaultLangage = localStorage.getItem("langue")
    if (this.defaultLangage == null) {
      this.defaultLangage = "GB"
    }
    localStorage.setItem("langue", this.defaultLangage)
    this.toogleLanguage(this.defaultLangage)
  }

  render() {
    const { children } = this.props;
    const { locale, messages } = this.state;
    return (
      <Context.Provider value={this.state}>
        <IntlProvider
          key={locale}
          locale={locale}
          messages={messages}
        >
          {children}
        </IntlProvider>
      </Context.Provider>
    );
  }
}

export { IntlProviderWrapper, Context as IntlContext };
