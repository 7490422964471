import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import jwt from "jsonwebtoken";
import { signIn } from "../api/dpmoApiServices/AuthServices";
import { signupWithPw, signupWithPwInvited, signup } from "../api/dpmoApiServices/AuthServices";
import setAuthorizationToken from "../config/authorizationHeader";
import { ACCESS_TOKEN } from "../constant/token";
import { setCurrentUser, setUserAuthorisation } from "../ducks/auth/action-creators";
// import image from "../img/landing.png";
import image from "../img/fondLanding.jpg";
import dpmo from "../img/logodp.png";
import { getAllAuthorisationCurrentUser } from "../api/dpmoApiServices/GovernanceServices"
import { getUserByUsernameOrEmail, updateUserPassword } from "../api/dpmoApiServices/UserServices"
import store from "../ducks/store";
import FormTemplate from "../components/form/formTemplate";
import PasswordChangeForm from "../components/form/passwordChangeForm"
import Divider from '@material-ui/core/Divider';
import { getMyMembership, getCompany, getSessionUsers, getUserSession, encodeSession } from "../api/dpmoApiServices/CompanyServices"
import Tour from "./tour.js";


const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundImage: `url(${image})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      width: '100vw',
      height: '100vh'
    }
  },
  gradiant: {
    overflow: "hidden",
  },
  paper: {
    marginTop: theme.spacing(10),
    // marginBottom: theme.spacing(50),
    marginLeft: "auto",
    marginRight: theme.spacing(11),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(7),
    width: "min-content",
    borderRadius: 80,
    opacity: "90%",
  },
  paperSession: {
    outline: 'none',
    backgroundColor: theme.palette.background.paper,
    borderRadius: "30px",
    padding: "2vh",
    width: "40%",
    border: "3px solid #15c9b9",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",

  },
  paper2: {
    display: "flex",
    alignItems: "center",


  },
  paper3: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: theme.spacing(7),
    marginLeft: theme.spacing(7),
    padding: theme.spacing(3)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#26E1BC"
  },
  form: {
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#009179",

  },
  bigAvatar: {
    width: 45,
    height: 40
  },
  image: {
    width: '50vw',
    height: 'auto'
  },

}));

const initialValue = {
  email: "",
  name: "",
  familyName: "",
  role: "Company Admin",

};

function SignIn(props) {
  const classes = useStyles();

  const [subscribe, setSubscribe] = useState(initialValue);
  const [connexion, setConnexion] = useState({});
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [firstConnexion, setFirstConnexion] = useState(false);
  const [user, setUser] = useState({});
  const [openCloseInfoMess, setOpenCloseInfoMess] = useState(false)
  const [errorMessageName, setErrorMessageName] = useState(false)
  const [errorMessageFamilyName, setErrorMessageFamilyName] = useState(false)
  const [errorMessageMail, setErrorMessageMail] = useState(false)


  const handleChange = event => {
    const { name, value } = event.target;
    setSubscribe({ ...subscribe, [name]: value });
  };

  const handleChangePasswordConfirmation = event => {
    const { name, value } = event.target;
    setPasswordConfirmation(value);
  };

  const handleNewPassword = data => {
    updateUserPassword(data)
    setFirstConnexion(false)
    setSubscribe({ ...subscribe, password: "" })
    localStorage.setItem(ACCESS_TOKEN, null);
  }

  const signUp = async () => {

    subscribe.username = subscribe.email
    subscribe.name = subscribe.name + " " + subscribe.familyName
    var res = ''
    if (subscribe.username !== "" && subscribe.familyName !== "" && subscribe.name !== " " && subscribe.email !== "") {
      res = await signup(subscribe)
      if (res == 1) {
        handleOpenCloseInfoMess()
      }
    }
    if (subscribe.name == " ") {
      setErrorMessageName(true);
    }
    if (subscribe.familyName == "") {
      setErrorMessageFamilyName(true);
    }
    if (subscribe.email == "") {
      setErrorMessageMail(true);
    }
  }

  const handleOpenCloseInfoMess = () => {
    setOpenCloseInfoMess(!openCloseInfoMess)
  }

  const goToSignInPage = () => {
    const { history } = props;
    history.push("/signIn")
  }

  return (


    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openCloseInfoMess}
        onClose
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openCloseInfoMess}>
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center", height: "100%"
          }}>
            <Paper className={classes.paperSession}>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: 'center',
                  marginTop: "10px",
                  fontSize: "18px",
                  // color: "#15c9b9",
                  color: "#000000",
                  fontFamily: "Ubuntu",
                  width: "90%",
                  marginLeft: "5%",
                  marginTop: "1vh"
                }}>
                  {"Hi " + subscribe.name + " ,\n"}<br /><br /><br />{
                    "Thank you for signing up as a new user in DpmoColab basic version. An E-mail is sent to your Email address “Email” with the login and password information \n"
                  }<br /><br />{
                    "Please check your SPAM in case the Email does not appear in your E-mail inbox.\n"
                  }<br /><br /><br /><br />{
                    "Thank you, DpmoColab Team"
                  }
                </p>
                <IconButton onClick={goToSignInPage} style={{ cursor: "pointer" }}>
                  <Close />
                </IconButton>
              </div>
            </Paper>
          </div>
        </Fade>
      </Modal>


      <Paper className={classes.paper} id="guide_0">
        {/*<Tour></Tour>*/}
        <Avatar
          alt="DPMO"
          src={dpmo}
          variant="rounded"
          className={classes.bigAvatar}
        />

        <div className={classes.paper3}>
          {/* <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Sign in
                </Typography> */}
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="name"
              label="Name"
              autoComplete="current-password"
              onChange={handleChange}
              value={subscribe.name}
            />

            {errorMessageName &&
              <span style={{ color: "red" }}>Name undefined</span>
            }

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="familyName"
              label="Last Name"

              autoComplete="current-password"
              onChange={handleChange}
              value={subscribe.familyName}
            />

            {errorMessageFamilyName &&
              <span style={{ color: "red" }}>Family Name undefined</span>
            }

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={handleChange}
              value={subscribe.email}
            />

            {errorMessageMail &&
              <span style={{ color: "red" }}> Email invalid or already exists</span>
            }

            <Button
              // type="submit"
              fullWidth
              variant="contained"
              //color="primary"
              onClick={signUp}
              className={classes.submit}
              id="guide_1"
            >
              Sign Up
            </Button>
          </form>

          {/* <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Email Address"
              name="usernameOrEmail"
              autoComplete="email"
              onChange={handleChange}
              value={connexion.usernameOrEmail}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              autoComplete="current-password"
              onChange={handleChange}
              value={connexion.password}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              //color="primary"
              onClick={handleSubmit}
              className={classes.submit}
              id="guide_1"
            >
              Sign In
                  </Button>
          </form> */}



          <Divider />
        </div>


      </Paper>

      {firstConnexion &&
        <FormTemplate name="Set a new password for your first connection">
          <PasswordChangeForm user={user} create={handleNewPassword} />
        </FormTemplate>
      }

    </>
  );
}

const mapDispatchToProps = dispatch => {
  //setCurrentUser,
  return {
    setCurrentUser: user => dispatch(setCurrentUser(user))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(SignIn));
