import {} from "./action-types.js";

const initialState = [
  {
    id: 31,
    name: "jean pierre de la compta"
  },
  {
    id: 32,
    name: "Jeremy"
  },
  {
    id: 33,
    name: "Lahcen"
  },
  {
    id: 34,
    name: "Clara"
  }
]; 

export const userList = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
