import {} from "./action-types.js";

const initialState = {
  obs: [
    {
      id: 1,
      name: "jean pierre de la compta"
    },
    {
      id: 2,
      name: "jeremy"
    },
    {
      id: 3,
      name: "Lahcen"
    }
  ],
  wbs: [
    {
      id: 1,
      name: "jean pierre de la compta"
    },
    {
      id: 2,
      name: "jeremy"
    },
    {
      id: 3,
      name: "Lahcen"
    }
  ]
};

export const companyDetail = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
