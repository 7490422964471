import React, { useState, useEffect } from "react";
import { DirectLine } from 'botframework-directlinejs';
import ReactWebChat from 'botframework-webchat';
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Jeano from "../../img/jeano.png";
import { Divider } from "@material-ui/core";
import { getMyMembership } from "../../api/dpmoApiServices/CompanyServices";

const useStyles = makeStyles({
  avatar: {
    margin: 10
  },
  bigAvatar: {
    margin: 10,
    width: 50,
    height: 50,
    boxShadow: '13px 10px 49px 5px rgba(0,145,120)'
  },
  header:{
    textAlign: "center",
    fontSize: "1.2em",
    background: "linear-gradient(0deg, rgb(0,145,120) 0%, rgb(0,145,120) 100%)",
    color: "White"
  },
  box:{
    width: "220px",
    border: "solid 1px"
  },
  chatBot: {
    height: "430px",
    width: "100%",
  }
});

const styleOptions = {
  //To see full parameters: https://github.com/microsoft/BotFramework-WebChat/blob/master/packages/component/src/Styles/defaultStyleOptions.js
  rootHeight:"430px",

  //Bubble
  bubbleMaxWidth:"80%",
  bubbleBackground:"linear-gradient(0deg, rgb(0,145,120) 0%, rgb(0,145,120) 100%)",
  bubbleTextColor: "White",
  bubbleBorderRadius:"10",

  bubbleFromUserBackground: 'rgba(0,145,120, .1)',

  //SendBox
  sendBoxHeight: 30,
  sendBoxBorderBottom: 'solid 1px',
  sendBoxBorderLeft: 'solid 1px',
  sendBoxBorderRight: 'solid 1px',
  sendBoxBorderTop: 'solid 1px',
  sendBoxButtonColor:"rgb(0,145,120)",
  hideUploadButton: "true",
  
  //Avatar
  /*
  botAvatarBackgroundColor:"Red",
  botAvatarInitials:"JB",
  */
}

const Chatbot = (match) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({userSummary:{},role:{}})
  const [speech, setSpeech] = useState(null)


  const directline = new DirectLine({
    secret:"0ZMvkTcBdUM.UOSKJKEqRQraxwZIg2R3OnibamXHxN3SPM70_8bjgJg",
    websocket: true,
    user:{id: state.id, name: state.name}
  })



  useEffect(() => {
    setOpen(false)
  }, [match.url]);

  useEffect(() => {
    async function fetchData(){
      getMyMembership().then(resp=>(setState(resp[0])))
      setSpeech({ webSpeechPonyfillFactory: await window.WebChat.createCognitiveServicesSpeechServicesPonyfillFactory({
        subscriptionKey: "1c94541fe83e42f3af6667e8afa02b51", region:"westus" })
      });
    }
    fetchData()
  }, []);

 
  const toggleClick = () => {
    setOpen(!open);
  };

  return (
    <div
      style={{ left: "10px", bottom: "15px", position: "fixed", zIndex: 5000 }}
    >
      <div style={{ backgroundColor: "rgb(0,145,120)" }}>
        {open && (
          <div className={classes.box}>

            <header className={classes.header}>Jeano Bot</header>
            <Divider/>
              <ReactWebChat
                styleOptions={styleOptions} 
                className={classes.chatBot} 
                directLine={directline}
                webSpeechPonyfillFactory={speech.webSpeechPonyfillFactory}
                username={state.userSummary.name} userID={state.role.name}/>
          </div>
        )}
      </div>
      <Avatar
        alt="Jeano"
        src={Jeano}
        className={classes.bigAvatar}
        onClick={toggleClick}
      />
    </div>
  );
}
export default Chatbot